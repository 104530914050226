import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule, CanActivate } from '@angular/router';
import { HttpModule } from '@angular/http';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppComponent } from './components/app/app.component';
import { NavMenuComponent } from './components/navmenu/navmenu.component';
import { LoginComponent } from './components/Login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ArancelComponent } from './components/arancel/arancel.component';
import { ArancelesAreaComponent } from './components/ArancelesArea/ArancelesArea.component';
import { OrdenesPagoComponent } from './components/ordenespago/ordenespago.component';

import { ReporteComponent } from './components/reporte/reporte.component';
import { ExoneracionComponent } from './components/Exoneracion/exoneracion.component';
import { DataService } from './_service/globalsvariable';



import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import {
  CanActivateRouteGuard as AuthGuard
} from './_service/can-activate-route.guard';
import { NgxMaskModule } from 'ngx-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule, MatInputModule } from '@angular/material';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AgenteexternoComponent } from './components/agente/agenteexterno.component';
import { AgenteModalComponent } from './components/agente/agentemodal/agentemodal.component';
import { WINDOW_PROVIDERS } from './window.provides';
import { SampleService } from './_service/sample.service';
import { ChartsModule } from 'ng2-charts';
import { agenteexterno } from './components/agente/service/agenteexterno.service';
import { ExoneracionTotalAreaComponent } from './components/ExoneracionesTotalesArea/exoneracion.component';





@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    LoginComponent,
    HomeComponent,
    ArancelComponent,
    ArancelesAreaComponent,
    OrdenesPagoComponent,
    ExoneracionComponent,
    AgenteexternoComponent,
    ReporteComponent,
    AgenteModalComponent,
    ExoneracionTotalAreaComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    RouterModule.forRoot([
      { path: 'Login', component: LoginComponent  },
      { path: 'Inicio', component: HomeComponent, canActivate: [AuthGuard] },
      // tslint:disable-next-line: comment-format
      //{ path: 'denegado', component: DenegadoComponent, canActivate: [AuthGuard] },
      { path: 'ordenespago', component: OrdenesPagoComponent, canActivate: [AuthGuard]},
      { path: 'Aranceles', component: ArancelComponent, canActivate: [AuthGuard] },
      { path: 'Exoneracion', component: ExoneracionComponent, canActivate: [AuthGuard] },
      { path: 'ArancelesArea', component: ArancelesAreaComponent, canActivate: [AuthGuard] },
      { path: 'Reportes', component: ReporteComponent, canActivate: [AuthGuard] },
      { path: 'agente', component: AgenteexternoComponent, canActivate: [AuthGuard] },
      { path: 'ExoneracionTotalPorArea', component: ExoneracionTotalAreaComponent, canActivate: [AuthGuard]},
      { path: '', redirectTo: 'Login', pathMatch: 'full' },
      { path: '**', redirectTo: 'Login', pathMatch: 'full' }
    ]),
    Ng4LoadingSpinnerModule.forRoot(),
    NgxPaginationModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    PdfViewerModule,
    ChartsModule
  ],
  providers: [
    AuthGuard,
    DataService,
    WINDOW_PROVIDERS,
    SampleService,
    agenteexterno
  ],
  bootstrap: [AppComponent]
})
export class AppModule {


}
