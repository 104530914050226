import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {LoginForm} from './LoginForm';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NgForm } from '@angular/forms'
import { HttpService } from '../../_service/HttpService';
import { DataService } from '../../_service/globalsvariable';
import { Http, Headers, RequestOptions, Response  } from '@angular/http';
import { SampleService } from '../../_service/sample.service';










//import { AlertService, AuthenticationService } from '../_services/index';

@Component({
    selector: 'Login',
    templateUrl: './Login.component.html',
    providers : [HttpService]
    
})

export class LoginComponent implements OnInit  {
  dse: DataService;
  headers: any;
  Token: any;
    options: any;
  host: string ="";
  constructor (
    private sampleService:SampleService, private http: Http,private _httpService : HttpService, private router: Router, private spinnerService: Ng4LoadingSpinnerService, dataservice: DataService
  ) {
    this.dse = dataservice;    
    var hd = new Headers();
        hd.append('Content-Type', 'application/json');
    var ops = new RequestOptions({ headers: hd });
        
      this.http.post('../Usuarios/tokenizador',  ops).subscribe(data => {
        if (data.status == 200) {
          this.Token = data.json().token;
          this.headers = new Headers();
          this.headers.append('Content-Type', 'application/json');
          this.headers.append('Authorization', 'Bearer ' + data.json().token);
          this.options = new RequestOptions({ headers: this.headers });
          this.host =  this.sampleService.protocol()+"//"+this.sampleService.getHostname();
        if(this.Token != null || this.Token != undefined )
          this.router.navigate(['/Inicio']);
        }
        });
    
  }

    url: any;
    merror: boolean=false;
  merror2: boolean = false;
  searchParams: any;
 

  
    
 

    LoginModel = new LoginForm();
   


   

  public save(f: NgForm) {
        this.merror = false;
      this.spinnerService.show();
     
      this._httpService.Login(this.LoginModel).subscribe(data => {

        if (data.status == 200) {
          localStorage.setItem('currentUser', this.LoginModel.email);
          localStorage.setItem('Token', data.json().token);
          localStorage.setItem('Usuario', data.json().usuario);
          localStorage.setItem('IdArea', data.json().idarea);
          localStorage.setItem('Area', data.json().area);


          // var tt = JSON.parse(localStorage.getItem('Recintos')) as Person;

          this.spinnerService.hide();
        
        }

      }, error => {

        this.merror = true;
        this.spinnerService.hide();
      });
      
        
  }

    public redirctoffice() {
      window.location.href = "https://login.microsoftonline.com/e119fcff-4f35-4338-8343-76d459894b6b/oauth2/v2.0/authorize?client_id=35eb8651-a334-4c6a-b792-9b6470ab03a5&redirect_uri="+this.host+"/loginoffice365&response_type=code&scope=user.read%20mail.read&prompt=login";

    }

 

   

    ngOnInit() {      
      this.merror = false;
   

      //this.router.navigate(['/home']);
      this.searchParams = new URLSearchParams(window.location.search);
     
      
      setTimeout(function () {
       
      }, 5000);
    }

   
}


export class Person {
    recinto: string = "";
    idPerfil: number = 0;
    idRecinto: number = 0;
    regAnulado: boolean = false;
}
