export class usuarioarancel {   
    idArea?: string;   
  area?: string;
  idUsuarioArancel: number;
  idArancelArea?: number;
  cod_empleado?: number;
  empleado?: string = "";
  usuario?: string= "";
  noInterno: string = "";


  noempleado?: number;
  count?: number;
  
}
