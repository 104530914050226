import { Component, Inject, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Http, Headers, RequestOptions, Response  } from '@angular/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Observable, interval} from 'rxjs';
import { Router, NavigationStart, Event } from '@angular/router';

import { DataService } from '../../_service/globalsvariable';



declare var $: any;



import { NgxMaskModule } from 'ngx-mask';


import * as XLSX from 'xlsx';
import { agente } from 'src/app/_class/agente';
import { agenteexterno } from './service/agenteexterno.service';





@Component({
    selector: 'agenteexterno',
  templateUrl: './agenteexterno.component.html',
  styleUrls: ['./agenteexterno.component.css']

})


export class AgenteexternoComponent implements OnInit {

    display = 'none';
    /* @ViewChild('autocompleteorigen', {static: false}) autocompleteorigen;
    @ViewChild('autocompletedestino', {static: false}) autocompletedestino;
    @ViewChild('modalcopi', {static: false}) modalcopi : ElementRef;
 */
    public data: agenteexterno[];



      Tk: any;



  searchModel  = new agente;

  AgenteModel = new agente;



    nuevo: boolean;
    errorareas: boolean;
    errorgestionar: boolean;
    errorguardar: boolean;
    erroreliminar: boolean;
    selected: string;
    headers: any;
    Token: any;
    pagination = 10;
    pagination2 = 10;
    pagination3 = 10;
    transac = false;

    options: any;
    timerbool = true;
    p1 = 1;
    p2 = 1;
    p3 = 1;
    timer: any;
    timer2: any;
  hashData = '';
  hashDatapermisos = '';

  SaveModel = new agente;

  editModel = new agente;

  dataservice: DataService;
  keyword = 'nombre';
  keyword2 = 'procedencia';

  pantalla = 'AgenteExterno';
  permisoagregar = false;
  permisoeditar = false;
  permisoeliminar = false;
  permisoexcel = false;


  errorcopiar = false;
  successcopiar = false;
  listidentificacion: any;
  errornombre: boolean = false;
  erroridentificador: boolean = false;
  servicioagente: agenteexterno;



  //@ViewChild('itemTemplate2') icuenta;



  constructor(private http: Http, private spinnerService: Ng4LoadingSpinnerService, private router: Router, dataservice: DataService, agenteexterno: agenteexterno) {


        this.servicioagente = agenteexterno;

        this.searchModel.nombre = '';
        this.searchModel.cedula = '';
        this.searchModel.identificador = '';
        this.searchModel.direccion = '';
        this.searchModel.tipoidentificador = '';

        this.SaveModel.idAgenteExterno = 0;

        this.errorareas = false;
        this.errorgestionar = false;
        this.errorguardar = false;
        this.erroreliminar = false;

        this.nuevo = true;



    this.dataservice = dataservice;


    }

  ngOnInit() {

    let hd = new Headers();
    hd.append('Content-Type', 'application/json');
let ops = new RequestOptions({ headers: hd });

  this.http.post('../Usuarios/tokenizador',  ops).subscribe(data => {
    if (data.status == 200) {
      this.Token = data.json().token;
      this.headers = new Headers();
      this.headers.append('Content-Type', 'application/json');
      this.headers.append('Authorization', 'Bearer ' + data.json().token);
      this.options = new RequestOptions({ headers: this.headers });
      this.Modelchange();
      this.searchlistaidentificacion();
     // this.listadepositos();
     this.Modelchangepermisos();
     this.autorizados();
    }
    });




  }



    ngOnDestroy() {
        this.timerbool = false;
  }




  autorizados() {
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.guardar, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoagregar = true;
      }

      }, error => {

        if (error.status == 401) {
          this.permisoagregar = false;
        }


      }

      );
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.editar, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoeditar = true;
      }

      }, error => {

        if (error.status == 401) {
          this.permisoeditar = false;
        }
      });
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.eliminar, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoeliminar = true;
      }

      }, error => {

        if (error.status == 401) {
          this.permisoeliminar = false;
        } }

    );
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.excel, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoexcel = true;
      }

      }, error => {

        if (error.status == 401) {
          this.permisoexcel = false;
        }
      });

  }
    openmodal(nueo: boolean): void {

        this.nuevo = nueo;
        if (this.nuevo) {

        } else {

        }

    }

    stopUpdate(): void {
      this.timer.dispose();
      this.timer2.dispose();
    }

    cerrarmodaledit(): void {
      <any> $('#AgenteModalEdit').modal('hide');
      //console.log(this.servicioagente);
     }
    deletemodal(): void {

        (<any> $('#DeleteModal')).modal('show');



  }



  export(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('selector'));
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Aranceles');
    /* save to file */
    XLSX.writeFile(wb, 'Aranceles Excel ' + new Date().toLocaleDateString() + '.xlsx');

  }



  Gestionar(): void {
    this.errorgestionar = false;
        if (this.nuevo) {
            this.guardar(true);
        } else {
            this.guardar(false);

        }
    }



    public clear(): void {

        this.data = [];

      this.searchModel = new agenteexterno();

      this.errorguardar = false;


    }



  public selecttable(event: any, item: any) {

    this.SaveModel.idAgenteExterno = item.idAgenteExterno;
    this.SaveModel.nombre = item.nombre;
    this.SaveModel.procedencia = item.procedencia;
    this.SaveModel.usuariocreacion = item.usuariocreacion;
    this.SaveModel.identificacion = item.identificacion;
    this.SaveModel.idIdentificacion = item.idIdentificacion;
    this.SaveModel.identi = item.identi;
    this.SaveModel.fechacreacion = item.fechacreacion;

    //this.AgenteModel.idAgenteExterno = this.SaveModel.idAgenteExterno;
    this.AgenteModel.nombre = this.SaveModel.nombre + '';
    this.AgenteModel.procedencia = this.SaveModel.procedencia + '';
    this.AgenteModel.identificacion = this.SaveModel.identificacion + '';
    this.AgenteModel.idIdentificacion = this.SaveModel.idIdentificacion + '';
    this.AgenteModel.identi = this.SaveModel.identi + '';
    this.servicioagente = this.AgenteModel;
    console.log(this.servicioagente);
    //this.AgenteModel.identificacion = this.SaveModel.identificacion+"";




    }



     search(): void {
        // this.spinnerService.show();

        this.http.get('../AgenteExterno/searchAgente?_limit=' + this.pagination + '&_page=' + this.p1 + '&nombre=' + this.searchModel.nombre + '&procedencia=' + this.searchModel.procedencia + '&identificacion=' + this.searchModel.identificacion + '&ididentificacion=' + this.searchModel.tipoidentificador + '&usuario=' + this.searchModel.usuariocreacion + '&fecha=' + this.searchModel.fechacreacion , this.options).subscribe(data => {

              if (data.status == 200) {



                      this.data = data.json().data;
                      this.SaveModel.count = data.json().count;

              }


          }, error => {



              }, () => {
                //   this.spinnerService.hide();
              });



        }



     searchlistaidentificacion(): void {
      // this.spinnerService.show();

      this.http.get('../AgenteExterno/listaidentificacion' , this.options).subscribe(data => {

            if (data.status == 200) {

              this.listidentificacion = data.json();




            }


        }, error => {



            }, () => {
              //   this.spinnerService.hide();
            });



      }

      existeagente(): void {
        // this.spinnerService.show();
        if (this.SaveModel.idAgenteExterno == 0) {
        this.http.get('../AgenteExterno/Agenteexistente?nombre=' + this.AgenteModel.nombre , this.options).subscribe(data => {

              if (data.status == 200) {

                this.errornombre = data.json().length > 0 ? true : false;

              }


              }, error => {



                  });
                  
              }else{
                this.errornombre  = false;
              }

            
        }

        existeidagente(): void {
          // this.spinnerService.show();
          if (this.SaveModel.idAgenteExterno == 0) {
          this.http.get('../AgenteExterno/Idexistente?identificacion=' + this.AgenteModel.identificacion , this.options).subscribe(data => {

                if (data.status == 200) {

                  this.erroridentificador = data.json().length > 0 ? true : false;

                }


            }, error => {



                }, () => {
                  //   this.spinnerService.hide();
                });

              }else{
                this.erroridentificador  = false;
              }

             

          }





    Modelchange(): void {
        // this.spinnerService.show();
      this.http.get('../AgenteExterno/ModelChangeAgente?_limit=' + this.pagination + '&_page=' + this.p1 + '&nombre=' + this.searchModel.nombre + '&procedencia=' + this.searchModel.procedencia + '&identificacion=' + this.searchModel.identificacion + '&usuario=' + this.searchModel.usuariocreacion + '&fecha=' + this.searchModel.fechacreacion, this.options).subscribe(data => {

            if (data.status == 200) {

                if (this.hashData != data.json().hash) {
                    this.hashData = data.json().hash;
                    this.search();

                }
                //this.spinnerService.hide();
            }


        }, error => {

            //  this.spinnerService.hide();

        }, () => {
            //this.spinnerService.hide();

        });

  }

  Modelchangepermisos(): void {
    // this.spinnerService.show();
    this.http.get('../Usuarios/ModelChangePermisos?pantalla=' + this.pantalla, this.options).subscribe(data => {

      if (data.status == 200) {

        if (this.hashDatapermisos != data.json().hash) {
          this.hashDatapermisos = data.json().hash;
          this.autorizados();

        }
        //this.spinnerService.hide();
      }


    }, error => {

      //  this.spinnerService.hide();

    }, () => {
      //this.spinnerService.hide();

    });





  }


    getPage(page: number) {
        this.p1 = page;
        this.search();

    }





    guardar(nuevo: boolean): void {


  
      console.log(this.AgenteModel, this.errornombre, this.erroridentificador)

    if (this.errornombre == false && this.erroridentificador == false  && this.AgenteModel.nombre.length > 0 && this.AgenteModel.procedencia.length > 0 && this.AgenteModel.identificacion.length > 0 && Number(this.AgenteModel.idIdentificacion ) > 0 ) {
            this.errorgestionar = false;
            this.errorguardar = false;
            this.spinnerService.show();
            this.transac = true;

            if (nuevo) {
              this.http.post('../AgenteExterno/Create', JSON.stringify({

                  nombre: this.AgenteModel.nombre, identificacion : this.AgenteModel.identificacion, idIdentificacion: this.AgenteModel.idIdentificacion, procedencia: this.AgenteModel.procedencia

                }), this.options).subscribe(data => {

                    if (data.status == 200) {

                        if (data.json().message == 'error') {
                            this.errorguardar = true;

                        } else {
                            (<any>$('#AgenteModal')).modal('hide');


                          this.search();

                        }


                        this.transac = false;
                    }

                }, error => {
                  this.transac = false;


                }, () => { this.spinnerService.hide(); });
            } else {
                this.http.post('../AgenteExterno/Edit', JSON.stringify({
                  idAgenteExterno: this.SaveModel.idAgenteExterno,

                    nombre: this.AgenteModel.nombre, identificacion : this.AgenteModel.identificacion, idIdentificacion: this.AgenteModel.idIdentificacion, procedencia: this.AgenteModel.procedencia


                }), this.options).subscribe(data => {

                    if (data.status == 200) {

                        if (data.json().message == 'error') {
                            this.errorguardar = true;

                        } else {
                         (<any>$('#AgenteModalEdit')).modal('hide');
                          this.search();

                        }

                        this.transac = false;

                    }

                }, error => {

                  this.transac = false;

                }, () => { this.spinnerService.hide(); });
            }





        } else {
            this.errorgestionar = true;

        }
    }






    eliminar(): void {
        if (this.SaveModel.idAgenteExterno > 0) {
            this.erroreliminar = false;
            this.spinnerService.show();

            this.http.post('../AgenteExterno/Delete', JSON.stringify({
                idAgenteExterno: this.SaveModel.idAgenteExterno
            }), this.options).subscribe(data => {

                if (data.status == 200) {

                    if (data.json().message == 'error') {
                      this.erroreliminar = true;

                        this.spinnerService.hide();
                    } else {
                      (<any>$('#DeleteModal')).modal('hide');
                        this.spinnerService.hide();
                      this.search();



                        }
                }

            }, error => {



            }, () => {  });

        }

  }


}


















