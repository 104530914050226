export class ordenpago {
    idOrdenPago: number = 0;
    noOrdenPago: string = "";
    idArea: string = "";
    idRecinto: string = "";
    area: string = "";
    recinto: string = "";
    recibimos: string = "";
    textoIdentificador:string = "";
    identificador:string = "";
    usuarioRemitente: string = "";
    sistema: string = "";
    fechaEnvio: string = "";
    codrecibo: string = "";
    emailNotificacion: string = "";
    observacion : string = "";
    idTipoDeposito: number = 0;
    tipoDeposito: string = "";
    codigoempleado: string = "";
    nombreagente: string = "";
    proveedor : string="";
    count: number = 0;
    reganulado:boolean;
    checked?:boolean = false;

}
