import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot  } from '@angular/router';
import { DataService } from '../_service/globalsvariable';
import { Http, Headers, RequestOptions } from '@angular/http';




@Injectable()
export class CanActivateRouteGuard implements CanActivate {

  Token: DataService;
  route: string;
  http: Http;
  headers: Headers;
  options: any;
  constructor(private router: Router, tk: DataService, Http : Http) { this.Token = tk; this.http = Http; }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    this.route = route.url[0].path;
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Authorization', 'Bearer ' + this.Token.Token);
    this.options = new RequestOptions({ headers: this.headers });

      if (this.Token.Token === undefined || this.Token.Token === null) {
        this.router.navigate(['login'],{

        });
        
        return false;
      } else {
          let access = new Promise((resolve, reject) => {
           this.http.post('../Usuarios/Acceso', 
            JSON.stringify({
            url: this.route})
            , this.options).toPromise().then(
              res => {
         
                resolve(res.ok);
                return res.ok;
              }
           ,
              msg =>{
                this.router.navigate(['login'],{

                });
               reject(msg.ok)
               return msg.ok;
              }
            );
          });
         
        return true;
      }
    }
}
