export class ordenarancel {
    idDetalleOrdenPago: number;
    idOrdenPago: number;
    idArancelPrecio: number;
    noOrdenpago?: number;
    idExoneracion?: number;
    exonerado?: number;
    idAreaIngreso?: number;
    areaIngreso?: string;
    descuento: string;
    fechaCreacion: string;
    usuarioCreacion: string;
    concepto: string;
    precio: number = null;
    precioVariable: number;
    isPrecioVariable: boolean;
    moneda: string;
    arancel: string;
    simbolo?: string;
    color?: string;
    complete?: string;
    styles?: any;

    count: number;


}


export class ordenarancelrep {
    idDetalleOrdenPago: number;
    idOrdenPago: number;
    idArancelPrecio: number;
    noOrdenpago?: number;
    idExoneracion?: number;
    exonerado?: number;
    idAreaIngreso?: number;
    areaIngreso?: string;
    areaGenerada?:string;
    descuento: string;
    fechaCreacion: string;
    usuarioCreacion: string;
    concepto: string;
    precio: any;  
    precioVariable: number;
    isPrecioVariable: boolean; 
    moneda: string;
    arancel: string;
    simbolo?: string;
    color?: string;
    complete?: string;
    styles?: any;

    count: number;


}
