export class arancelarea {
    idArancelArea?: number;
  idRecinto?: number;
  idArancel?: number;
  idArea?: number;
  idAreaDestino?: number;
  recinto?: string;
  area?: string;
  areadestino?: string;
  descripcion?: string;
  idprecio?: number;
  precio?: string;
 idmoneda?: number;
  moneda?: string;
  count?: number;
  objectarea?: any;

}


