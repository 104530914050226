export class Exoneracion {
    idExoneracionTotalArea:number = 0 ;
          idArea: number = 0;
          area: string = "";
          fechaCreacion: string = '';
          
          usuarioCreacion:string="" ;

  
  count?: number = 0;
}
