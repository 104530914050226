import { Component, OnInit } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Router, NavigationStart, Event } from '@angular/router';
import { DataService } from '../../_service/globalsvariable';
import { pantalla } from '../../_class/pantalla';



@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']

})
export class HomeComponent implements OnInit{
    
 
    headers: any;
    options: any;
    isAuthorization: boolean;
    logouni: string;
    User: string;
    Area: string;
    

    pantallas:  pantalla[];

    constructor(
        private router: Router, private http: Http, dataservice: DataService
      ) {
        this.logouni = dataservice.imagenlogo;
        this.User = dataservice.User;
        this.Area = dataservice.Area;
        this.isAuthorization = false;  
        this.headers = new Headers();
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Authorization', 'Bearer ' + dataservice.Token);
        this.options = new RequestOptions({ headers: this.headers });
        if (dataservice.Token != null || dataservice.Token != undefined) {
          this.isAuthorization = true;
    
          router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
             
           
    
    
            }
          });
        }
           
        }

        randomimage(){
            return Math.floor(Math.random() * 20) + 1;
        }

        ngOnInit() {

           
        
            
        
            if (this.isAuthorization) {
              //this.printer(this.options);
              this.http.post('../Usuarios/menu',null, this.options).subscribe(data => {
                if (data.status == 200) {
                  this.pantallas = data.json().pantallas;
                 
                }
        
              });
        
            }
            
         
        
          }

}
