import { Component, OnInit, HostListener } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Router, NavigationStart, Event, ActivatedRoute, Params} from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataService } from '../../_service/globalsvariable';
import * as $ from 'jquery';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  navmenu: any;
  rouletout: any;
  navmenub: boolean = false;
  headers: any;
  options: any;
  isAuthorization:boolean = false;




  constructor(private router: Router, private http: Http, private ng4LoadingSpinnerService: Ng4LoadingSpinnerService, activatedRoute: ActivatedRoute, dataservice: DataService) {

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // this.spinnerService.show();
        var heads = new Headers();
        heads.append('Content-Type', 'application/json');
        var option2 = new RequestOptions({ headers: heads });

        this.http.post('../Usuarios/tokenizador', option2).subscribe(data => {
          if (data.status == 200 &&  data.json().token) {
            dataservice.Token = data.json().token;
            dataservice.User = data.json().usuario;
            dataservice.Area = data.json().area;
            dataservice.Email = data.json().email;
            this.headers = new Headers();
            this.headers.append('Content-Type', 'application/json');
            this.headers.append('Authorization', 'Bearer ' + dataservice.Token);
            this.options = new RequestOptions({ headers: this.headers });
           
            if (dataservice.Token != null || dataservice.Token != undefined) {
              this.navmenub = true;
              this.navmenu = "col-3";
              this.rouletout = "col-9 body-content";
              this.isAuthorization = true;
            } else {
              this.navmenub = false;
              this.navmenu = "";
              this.rouletout = "col-12 body-content";
              this.isAuthorization = false;
              
            }

            this.http.post('../Usuarios/Acceso', JSON.stringify({
              url: event.url
            }), this.options).subscribe(data => {

              if (data.status == 200) {
                //this.spinnerService.hide();


              }


            }, error => {

              if (error.status == 401) {

                if ((event.url == '/') || (event.url == '/Login') || (event.url == '/login') || (event.url == '/Denegado')) {

                }

                else {
                  this.router.navigate(['./Login']);
                }

                //this.spinnerService.hide();


              }

            }, () => {

              });


          }

        });
      }

    });

  }


  ngOnInit() {


   


  }

  openmenu() {
    (<any>$('#menusis')).removeClass('d-none');
    (<any>$('#menusis')).removeClass('animatemenu');
    (<any>$('#ruleta')).removeClass('animatemain');
    (<any>$('#menusis')).addClass('aniopenmenu');
    (<any>$('#navlarge')).removeClass('fadeOut');
    (<any>$('#navlarge')).addClass('fadeIn');
    (<any>$('#menusis')).addClass('fadeOut');
    (<any>$('#menusis')).removeClass('fadeIn');
    (<any>$('#ruleta')).addClass('aniclosemain');
    setTimeout(function () {
    
      (<any>$('#mainsis')).addClass('row');
      (<any>$('#menusis')).addClass('col-3');
     
     
      
        (<any>$('#ruleta')).addClass('col-9'); 
      
      
      
      (<any>$('#menuoculto')).addClass('d-none');
      (<any>$('#navlarge')).addClass('d-none');
      (<any>$('#ruleta')).removeClass('col-12');
    }, 300);

    setTimeout(function () {
      
      (<any>$('#menusis')).removeClass('aniopenmenu');
      (<any>$('#ruleta')).removeClass('aniclosemain');
    },300);

  }



  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    if ($event.pageY > 100) {

      (<any>$('#fixed-t')).addClass('floatingNav');

    } else {
      (<any>$('#fixed-t')).removeClass('floatingNav');

    }

  }
 
}
