import { Component, Inject, OnInit, ViewChild, ElementRef,Input } from '@angular/core';
import { Http, Headers, RequestOptions, Response  } from '@angular/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Observable, interval} from 'rxjs';
import { Router, NavigationStart, Event } from '@angular/router';

import { DataService } from '../../../_service/globalsvariable';



declare var $: any;



import { NgxMaskModule } from 'ngx-mask';


import * as XLSX from 'xlsx';
import { agente } from 'src/app/_class/agente';
import { inject } from '@angular/core/testing';
import { AgenteexternoComponent } from '../agenteexterno.component';
import {agenteexterno} from '../service/agenteexterno.service'





@Component({
    selector: 'agentemodal',
  templateUrl: './agentemodal.component.html',
  styleUrls: ['./agentemodal.component.css']

})


export class AgenteModalComponent extends AgenteexternoComponent {
 
  cerrarmodal():void{
   <any> $('#AgenteModal').modal('hide');
   //console.log(this.servicioagente);
  }


 
}


















