
import { Component, OnInit, HostListener } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { interval } from 'rxjs';
import { DataService } from '../../_service/globalsvariable';
import { agente } from '../../_class/agente';
import { areaingreso } from '../../_class/areaingreso';
import { deposito } from '../../_class/deposito';
import { empleado } from '../../_class/empleado';
import { estudiante } from '../../_class/estudiante';
import { exoneracion } from '../../_class/exoneracion';
import { ordenarancel } from '../../_class/ordenarancel';
import { ordenpago } from '../../_class/ordenpago';
import { proveedor } from '../../_class/proveedor';
import { recinto } from '../../_class/recinto';
import { area } from '../../_class/area';
declare var $: any;


import * as XLSX from 'xlsx';


@Component({
    selector: 'ordenespago',
  templateUrl: './ordenespago.component.html',
  styleUrls: ['./ordenespago.component.css']

})



export class OrdenesPagoComponent implements OnInit {

  keyword = 'arancel';
  notFoundtext = 'No hay resultados';

    display = 'none';


    public data: ordenpago[];
    public dataarancel: ordenarancel[];
    public aranceltabla: ordenarancel[];
    public aranceles: ordenarancel[];
    public recintos: recinto[];
    public depositos: deposito[];
    public areasingresos: areaingreso[];

    public exoneraciones: exoneracion[];

    public estudiantestabla:  estudiante[];
    public estudiantesmetabla:  estudiante[];
    public empleadostabla: empleado[];
    public proveedortabla: proveedor[];
    public searchconcepto = '';
    public agentetabla: agente[];
    public areatabla: area[];




    searchModel = new ordenpago();
    searchModel2 = new ordenarancel();
    OrdenModel = new ordenpago();
    ArancelModel = new ordenarancel();
    EstudianteModel = new estudiante();
    EmpleadoModel = new empleado();
    ProveedorModel = new proveedor();
    AgenteModel = new agente();
    AreaModel = new area();
    EstudianteMeModel =  new estudiante();




    nuevo: boolean;
    errorarancel: boolean;
    errorgestionar: boolean;
    errorguardar: boolean;
    transac = false;
    erroreliminar: boolean;
    selected: string;
    headers: any;
    options: any;
    p1 = 1;
    p2 = 1;
    timerbool = true;
    totalcordobas = 0;
    totaldolares = 0;
    totalcordobastemp = 0;
    totaldolarestemp = 0;
    totalexoneradocordobastemp = 0;
  totalexoneradodolarestemp = 0;
  totalexoneradocordobas = 0;
  totalexoneradodolares = 0;

    ultimaorden = 0;
    recintonum = 0;
    recintoblock = false;

    totaldescuento = 0;
  totaldescuentotemp = 0;
  totalexonerado = 0;
  totalexoneradotemp = 0;
  hashData = '';
  hashDatapermisos = '';
  Token: any;
  pagination = 10;
  pagination2 = 10;

    SaveModel = new ordenpago();
    SaveModel2 = new ordenarancel();
  editModel = new ordenarancel();

  pantalla = 'Ordenes de Pago';
  permisoagregar = false;
  permisoeditar = false;
  permisoeliminar = false;
  permisoexcel = false;
  dataservice: DataService;
  permisoagregaragente: boolean;





  constructor(private http: Http, private spinnerService: Ng4LoadingSpinnerService, private dataserv: DataService) {

    this.dataservice = dataserv;

        this.searchModel.noOrdenPago = '';
        this.searchModel.noOrdenPago
        this.searchModel.recibimos = '';
        this.searchModel.area = '';
        this.searchModel.recinto = '';
        this.searchModel.usuarioRemitente = '';
        this.searchModel.fechaEnvio = '';
        this.searchModel.codrecibo = '';
        this.searchModel2.arancel = '';
        this.searchModel2.areaIngreso = '';

        this.searchModel.tipoDeposito = '';
        this.SaveModel.idOrdenPago = 0;





        this.errorarancel = false;
        this.errorgestionar = false;
        this.errorguardar = false;
        this.erroreliminar = false;
        this.nuevo = true;
        this.recintoblock = false;
      this.clear();


  }



  ngOnInit() {
    const hd = new Headers();
    hd.append('Content-Type', 'application/json');
    const ops = new RequestOptions({ headers: hd });

    this.http.post('../Usuarios/tokenizador', ops).subscribe(data => {
      if (data.status == 200) {
        this.Token = data.json().token;
        this.headers = new Headers();
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Authorization', 'Bearer ' + data.json().token);
        this.options = new RequestOptions({ headers: this.headers });
        this.listarecintos();
        this.listadepositos();
        this.Modelchange();
        this.Modelchangepermisos();
      }
    });



        interval(120000).subscribe(() => {
         if (this.timerbool) {
           this.Modelchange();
         }
        });



    }

   

    ngOnDestroy() {
        this.timerbool = false;
    }


    Modelchange(): void {
        // this.spinnerService.show();
      this.http.get('../OrdenPagos/ModelChangeArancel?_limit=' + this.pagination + '&_page=' + this.p1 + '&NoOrdenPago=' + this.searchModel.noOrdenPago + '&recinto=' + this.searchModel.recinto + '&area=' + this.searchModel.area + '&usuarioremitente=' + this.searchModel.usuarioRemitente + '&fecha=' + this.searchModel.fechaEnvio + '&codrecibo=' + this.searchModel.codrecibo +  '&tipodeposito=' + this.searchModel.tipoDeposito + '&concepto=' + this.searchconcepto, this.options).subscribe(data => {

            if (data.status == 200) {

                if (this.hashData != data.json().hash) {
                    this.hashData = data.json().hash;
                    this.search();

                }
                //this.spinnerService.hide();
            }


        }, error => {

            //  this.spinnerService.hide();

        }, () => {
            //this.spinnerService.hide();

        });





    }

    search(): void {
       // this.spinnerService.show();
      this.http.get('../OrdenPagos/searchOrdenes?_limit=' + this.pagination + '&_page=' + this.p1 + '&NoOrdenPago=' + this.searchModel.noOrdenPago + '&recinto=' + this.searchModel.recinto + '&area=' + this.searchModel.area + '&usuarioremitente=' + this.searchModel.usuarioRemitente + '&fecha=' + this.searchModel.fechaEnvio + '&codrecibo=' + this.searchModel.codrecibo + '&concepto=' + this.searchconcepto +   '&tipodeposito=' + this.searchModel.tipoDeposito + '&ishash=false&identificador=' + this.searchModel.identificador, this.options).subscribe(data => {

            if (data.status == 200) {


                    this.data = data.json().data;
                    this.dataarancel = null;

                    this.SaveModel.count = data.json().count;

                //this.spinnerService.hide();
            }


        }, error => {

          //  this.spinnerService.hide();

            }, () => {
                //this.spinnerService.hide();

        });





    }

    search2(): void {
        this.spinnerService.show();
      this.http.get('../OrdenPagos/searchOrdenesAranceles?_limit=' + this.pagination2 + '&_page=' + this.p2 + '&idOrdenPago=' + this.SaveModel.idOrdenPago + '&arancel=' + this.searchModel2.arancel + '&precio=' + this.searchModel2.precio + '&areaIngreso=' + this.searchModel2.areaIngreso, this.options).subscribe(data => {

            if (data.status == 200) {
                this.spinnerService.hide();
                this.dataarancel = data.json().data;
                this.SaveModel2.count = data.json().count;
              this.sumatotal();

              this.sumexonerado();
                this.spinnerService.hide();

            }


        }, error => {

            this.spinnerService.hide();

        }, () => { });


    }

    public selecttable(event: any, item: any) {


        //if (item.codRecibo != null || item.regAnulado == true) {
        //    this.SaveModel = new ordenPago();
        //    this.OrdenModel = new ordenPago();
      //} else
      {

        if (item.emailNotificacion == undefined) {
          item.emailNotificacion = '';
        }

        if (item.observacion == undefined) {
          item.observacion = '';
        }
            this.SaveModel.idOrdenPago = item.idOrdenPago;
            this.SaveModel.noOrdenPago = item.noOrdenPago;
            this.SaveModel.idArea = item.idArea;
            this.SaveModel.idRecinto = item.idRecinto;
            this.SaveModel.area = item.area;
            this.SaveModel.recinto = item.recinto;
            this.SaveModel.recibimos = item.recibimos;
            this.SaveModel.idTipoDeposito = item.idTipoDeposito;
            this.SaveModel.tipoDeposito = item.tipoDeposito;
            this.SaveModel.identificador = item.identificador;
            this.SaveModel.emailNotificacion = item.emailNotificacion;
            this.SaveModel.observacion = item.observacion;
            this.SaveModel.sistema = item.sistema;

            this.ultimaorden = Number(item.noOrdenPago);
            this.SaveModel.usuarioRemitente = item.usuarioRemitente;
            this.SaveModel.fechaEnvio = item.fechaEnvio;
            this.SaveModel.codrecibo = item.codRecibo;
            this.OrdenModel.idOrdenPago = item.idOrdenPago;
            this.OrdenModel.noOrdenPago = this.SaveModel.noOrdenPago + '';
            this.OrdenModel.area = this.SaveModel.area + '';
            this.OrdenModel.recinto = this.SaveModel.recinto + '';
            this.OrdenModel.recibimos = this.SaveModel.recibimos + '';
            this.OrdenModel.idTipoDeposito = this.SaveModel.idTipoDeposito + 0;
            this.OrdenModel.usuarioRemitente = this.SaveModel.usuarioRemitente + '';
            this.OrdenModel.emailNotificacion = this.SaveModel.emailNotificacion + '';
            this.OrdenModel.observacion = this.SaveModel.observacion + '';
            this.OrdenModel.sistema = this.SaveModel.sistema + '';
            this.SaveModel.codrecibo = this.SaveModel.codrecibo + '';
            this.SaveModel.reganulado = item.regAnulado;

            this.search2();


        }






    }

    public selecttable2(event: any, item: any) {

        this.SaveModel2.idArancelPrecio = item.idArancelPrecio;
        this.SaveModel2.idDetalleOrdenPago = item.idDetalleOrdenPago;
        this.SaveModel2.idAreaIngreso = item.idAreaIngreso;
        this.SaveModel2.areaIngreso = item.areaIngreso;
        this.SaveModel2.arancel = item.arancel;
        this.SaveModel2.descuento = item.descuento;
        this.SaveModel2.moneda = item.moneda;
        this.SaveModel2.precio = item.precio;
        this.SaveModel2.color = item.color;

    }

    public selecttable3(event: any, item: any) {

        this.editModel.idArancelPrecio = item.idArancelPrecio;
        this.editModel.idDetalleOrdenPago = item.idDetalleOrdenPago;
        this.editModel.arancel = item.arancel;
        this.editModel.descuento = item.descuento;
        this.editModel.concepto = item.concepto;
        this.editModel.moneda = item.moneda;
        this.editModel.precio = item.precio;
        this.editModel.color = item.color;

    }

    getPage(page: number) {
        this.p1 = page;
        this.search();

    }


    getPage2(page: number) {
        this.p2 = page;
        this.search2();

  }

   goToByScrollup() {
     window.scrollTo(0, 0);
  }
  goToByScrolldown() {
    window.scrollTo(0, document.body.scrollHeight);
  }


  Modelchangepermisos(): void {
    // this.spinnerService.show();
    this.http.get('../Usuarios/ModelChangePermisos?pantalla=' + this.pantalla, this.options).subscribe(data => {

      if (data.status == 200) {

        if (this.hashDatapermisos != data.json().hash) {
          this.hashDatapermisos = data.json().hash;
          this.autorizados();

        }
        //this.spinnerService.hide();
      }


    }, error => {

      //  this.spinnerService.hide();

    }, () => {
      //this.spinnerService.hide();

    });
  }



    autorizados() {
      this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
        permiso: this.dataservice.guardar, pantalla: this.pantalla

      }), this.options).subscribe(data => {

        if (data.status == 200) {
          this.permisoagregar = true;
        }

      }, error => {

        if (error.status == 401) {
          this.permisoagregar = false;
        }


      }

      );
      this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
        permiso: this.dataservice.editar, pantalla: this.pantalla

      }), this.options).subscribe(data => {

        if (data.status == 200) {
          this.permisoeditar = true;
        }

      }, error => {

        if (error.status == 401) {
          this.permisoeditar = false;
        }
      });
      this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
        permiso: this.dataservice.eliminar, pantalla: this.pantalla

      }), this.options).subscribe(data => {

        if (data.status == 200) {
          this.permisoeliminar = true;
        }

      }, error => {

        if (error.status == 401) {
          this.permisoeliminar = false;
        }
      }

      );
      this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
        permiso: this.dataservice.excel, pantalla: this.pantalla

      }), this.options).subscribe(data => {

        if (data.status == 200) {
          this.permisoexcel = true;
        }

      }, error => {

        if (error.status == 401) {
          this.permisoexcel = false;
        }
      });

      this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
        permiso: this.dataservice.guardar, pantalla: 'AgenteExterno'

      }), this.options).subscribe(data => {

        if (data.status == 200) {
          this.permisoagregaragente = true;
        }

      }, error => {

        if (error.status == 401) {
          this.permisoagregaragente = false;
        }


      }

      );
    }


    sumatotal(): void {
        const cor = this.dataarancel.filter(number => number.color === 'Cordoba');
        const dol = this.dataarancel.filter(number => number.color === 'Dolar');
        this.totalcordobas = cor.reduce((acc, cur) => acc + (Number(cur.precio)  - cur.exonerado), 0);
      this.totaldolares = dol.reduce((acc, cur) => acc + (Number(cur.precio)  - cur.exonerado), 0);
      this.totalexoneradocordobas = cor.reduce((acc, cur) => acc + (cur.exonerado), 0);
      this.totalexoneradodolares = dol.reduce((acc, cur) => acc + (cur.exonerado), 0);


  }






  sumexonerado(): void {
    const des = this.dataarancel.filter(x => Number(x.exonerado) > 0);
    this.totalexonerado = des.reduce((acc, cur) => acc + (Number(cur.exonerado)), 0);


  }

  sumexoneradotemp(): void {
    const des = this.aranceltabla.filter(x => Number(x.exonerado) > 0);
    this.totalexoneradotemp = des.reduce((acc, cur) => acc + (Number(cur.exonerado)), 0);


  }


    sumatotaltemp(): void {
        const cor = this.aranceltabla.filter(number => number.color === 'Cordoba');
        const dol = this.aranceltabla.filter(number => number.color === 'Dolar');

      this.totalcordobastemp = cor.reduce((acc, cur) => acc + (Number(cur.precio) - Number(cur.exonerado == undefined ? 0 : cur.exonerado)), 0);
      this.totaldolarestemp = dol.reduce((acc, cur) => acc + (Number(cur.precio) - Number(cur.exonerado == undefined ? 0 : cur.exonerado)), 0);
      this.totalexoneradocordobastemp = cor.reduce((acc, cur) => acc + (cur.exonerado), 0);
      this.totalexoneradodolarestemp = dol.reduce((acc, cur) => acc + (cur.exonerado), 0);

    }
  selectarancel(item): void {
    this.ArancelModel.idArancelPrecio = item.idArancelPrecio;
    this.ArancelModel.simbolo = item.simbolo;
      const aranceldetalle = this.aranceles.filter(x => x.idArancelPrecio == this.ArancelModel.idArancelPrecio);
      this.ArancelModel.isPrecioVariable = aranceldetalle[0].isPrecioVariable;
      this.listaareasingreso();
      this.listaexoneracion();




  }
  selectareaingreso(): void {
      const area = this.areasingresos.filter(x => x.idArea == this.ArancelModel.idAreaIngreso);
      this.ArancelModel.areaIngreso = area[0].recinto + ' - '  + area[0].area ;
  }


  async agregartab() {
  //  await this.listaexoneracion();
      const aranceldetalle = this.aranceles.filter(x => x.idArancelPrecio == this.ArancelModel.idArancelPrecio );
      const existExoneracion = this.aranceltabla.filter(x => x.idExoneracion == this.ArancelModel.idExoneracion );


      if (this.ArancelModel.idExoneracion > 0) {
        const exonerado = this.exoneraciones.filter(x => x.idExoneracion == this.ArancelModel.idExoneracion);
        // if(aranceldetalle.idExoneracion!= this.ArancelModel.idExoneracion)
        this.ArancelModel.exonerado = Number(exonerado[0].exonerado);
      }
     


        if (this.ArancelModel.isPrecioVariable) {
            if (this.ArancelModel.idArancelPrecio > 0 && Number(this.ArancelModel.precioVariable) > 0 ) {
              this.errorarancel = false;
              const array = { idArancelPrecio: this.ArancelModel.idArancelPrecio, idDetalleOrdenPago: 0, idOrdenPago: this.ultimaorden,
                 descuento: this.ArancelModel.descuento, fechaCreacion: '', usuarioCreacion: '', precio: this.ArancelModel.precioVariable, 
                 precioVariable: this.ArancelModel.precioVariable, isPrecioVariable: aranceldetalle[0].isPrecioVariable,
                  concepto: this.ArancelModel.concepto, moneda: String(aranceldetalle[0].moneda), arancel: String(aranceldetalle[0].arancel),
                   color: String(aranceldetalle[0].color), count: 0, areaIngreso: this.ArancelModel.areaIngreso, idAreaIngreso: this.ArancelModel.idAreaIngreso,
                    idExoneracion: this.ArancelModel.idExoneracion, exonerado: this.ArancelModel.exonerado, complete: this.ArancelModel.complete };
                const exist = this.aranceltabla.findIndex(x => x.idArancelPrecio == this.ArancelModel.idArancelPrecio && x.concepto == this.ArancelModel.concepto);

                if (exist > -1) {
                    this.aranceltabla.splice(exist, 1);
                }

                this.aranceltabla.push(array);
              this.sumatotaltemp();

              this.sumexoneradotemp();
                this.recintoblock = true;
              this.numeroorden();

                this.ArancelModel.descuento = 0 + '';
            } else {
                this.errorarancel = true;
            }

        } else {

        if (this.ArancelModel.idArancelPrecio > 0  && Number(this.ArancelModel.descuento) >= 0 && Number(this.ArancelModel.descuento) < Number(aranceldetalle[0].precio)) {
          this.errorarancel = false;
          const array = {
            idArancelPrecio: this.ArancelModel.idArancelPrecio, idDetalleOrdenPago: 0,
            idOrdenPago: this.ultimaorden, descuento: this.ArancelModel.descuento, fechaCreacion: '',
            usuarioCreacion: '', precio: aranceldetalle[0].precio, precioVariable: 0,
            isPrecioVariable: aranceldetalle[0].isPrecioVariable, concepto: this.ArancelModel.concepto,
            moneda: String(aranceldetalle[0].moneda), arancel: String(aranceldetalle[0].arancel),
            color: String(aranceldetalle[0].color), count: 0, areaIngreso: this.ArancelModel.areaIngreso,
            idAreaIngreso: this.ArancelModel.idAreaIngreso, 
            idExoneracion: existExoneracion.length == 0 ? this.ArancelModel.idExoneracion:null,
            exonerado: existExoneracion.length == 0 ? this.ArancelModel.exonerado : null , complete: this.ArancelModel.complete
          };
            const exist = this.aranceltabla.findIndex(x => x.idArancelPrecio == this.ArancelModel.idArancelPrecio && x.concepto == this.ArancelModel.concepto );

            if (exist > -1) {
                this.aranceltabla.splice(exist, 1);
            }



            this.aranceltabla.push(array);
          this.sumatotaltemp();


          this.sumexoneradotemp();
            this.recintoblock = true;
          this.numeroorden();

            this.ArancelModel.descuento = 0 + '';
        } else {
            this.errorarancel = true;
            }
        }
    }

    eliminartab(): void {

        const exist = this.aranceltabla.findIndex(x => x.idArancelPrecio == this.editModel.idArancelPrecio);

        if (exist > -1) {
            this.aranceltabla.splice(exist, 1);
          this.sumatotaltemp();

          this.sumexoneradotemp();


        }

        if (this.aranceltabla.length == 0) {
            this.recintoblock = false;
        }

    }



    openmodal(nueo: boolean): void {

        this.nuevo = nueo;
        if (this.nuevo) {
           this.errorgestionar = false;
            this.clear();
           // this.numeroorden();


        } else {
            this.aranceltabla = this.dataarancel;
            this.totaldolarestemp = this.totaldolares;
            this.totalcordobastemp = this.totalcordobas;
            this.OrdenModel.idRecinto = this.SaveModel.idRecinto;
            this.recintoblock = true;
            this.errorgestionar = false;
            this.listaaranceles();
            this.ultimaorden = Number(this.SaveModel.noOrdenPago);

        }



    }


    public clear(): void {

        this.aranceltabla = [];
        this.estudiantestabla = [];
        this.empleadostabla = [];
        this.proveedortabla = [];
        this.agentetabla = [];

        this.OrdenModel = new ordenpago;
        this.ArancelModel.descuento = 0 + '';
        this.ArancelModel.concepto = '';
        this.OrdenModel.idTipoDeposito = 0;
        this.OrdenModel.idRecinto = 0 + '';
        this.EstudianteModel = new estudiante();
        this.ProveedorModel = new proveedor();
        this.EmpleadoModel = new empleado();
        this.errorarancel = false;
        this.errorgestionar = false;
        this.totaldolarestemp = 0;
        this.totalcordobastemp = 0;
        this.ultimaorden = 0;
        this.recintoblock = false;
        this.errorgestionar = false;


    }
    public selectdeposito(): void {

      this.listaaranceles();

    }


    deletemodal(): void {

        (<any>$('#DeleteModal')).modal('show');



    }

    eliminar(): void {
        if (this.SaveModel.idOrdenPago > 0) {
            this.erroreliminar = false;
            this.spinnerService.show();

            this.http.post('../OrdenPagos/Delete', JSON.stringify({
                IdOrdenPago: this.SaveModel.idOrdenPago
            }), this.options).subscribe(data => {

                if (data.status == 200) {

                    if (data.json().message == 'error') {
                        this.erroreliminar = true;
                        this.spinnerService.hide();
                    } else {
                        (<any> $('#DeleteModal')).modal('hide');
                        this.SaveModel = new ordenpago();
                        this.OrdenModel = new ordenpago();
                        this.spinnerService.hide();
                        this.search();
                    }
                }

            }, error => {



            }, () => { });

        }

    }

    observacion(): void {
      if (this.SaveModel.idOrdenPago > 0) {
          this.erroreliminar = false;
          this.spinnerService.show();

          this.http.post('../OrdenPagos/ObservacionEdit', JSON.stringify({
              IdOrdenPago: this.SaveModel.idOrdenPago,
              Observacion: this.OrdenModel.observacion
          }), this.options).subscribe(data => {

              if (data.status == 200) {

                  if (data.json().message == 'error') {
                      this.erroreliminar = true;
                      this.spinnerService.hide();
                  } else {
                      (<any> $('#ObservacionModal')).modal('hide');
                      this.SaveModel = new ordenpago();
                      this.OrdenModel = new ordenpago();
                      this.spinnerService.hide();
                      this.search();
                  }
              }

          }, error => {



          }, () => { });

      }

  }
    listaaranceles(): void {

        if (this.nuevo) {

            if ( this.OrdenModel.idTipoDeposito > 0) {
                this.spinnerService.show();
                this.http.get('../OrdenPagos/listaaranceles?deposito=' + this.OrdenModel.idTipoDeposito, this.options).subscribe(data => {

                    if (data.status == 200) {
                        this.aranceles = data.json().data;
                        this.spinnerService.hide();
                    }

                }, error => {
                    this.spinnerService.hide();
                }, () => { });

            }
        } else {
            this.spinnerService.show();
            this.http.get('../OrdenPagos/listaaranceles?deposito=' + this.OrdenModel.idTipoDeposito, this.options).subscribe(data => {

                if (data.status == 200) {
                    this.aranceles = data.json().data;
                    this.spinnerService.hide();
                }

            }, error => {
                this.spinnerService.hide();
            }, () => { });



        }

    }


  listaareasingreso(): void {

      if (this.ArancelModel.idArancelPrecio > 0) {
        this.spinnerService.show();
        this.http.get('../OrdenPagos/listaareasingreso?arancel=' + this.ArancelModel.idArancelPrecio , this.options).subscribe(data => {

          if (data.status == 200) {
            this.areasingresos = data.json().data;
            this.ArancelModel.idAreaIngreso = data.json().areanum;
            this.spinnerService.hide();
          }

        }, error => {
          this.spinnerService.hide();
        }, () => { });

      }

  }


  async listaexoneracion() {

      if (this.ArancelModel.idArancelPrecio > 0) {
        this.spinnerService.show();

        const value = await
       this.http.get('../OrdenPagos/listaexoneracion?arancel=' + this.ArancelModel.idArancelPrecio + '&identificador=' + this.OrdenModel.tipoDeposito + '&IdTipoDeposito=' + this.OrdenModel.idTipoDeposito, this.options).subscribe(data => {

          if (data.status == 200) {
            this.exoneraciones = data.json().data;
            if (this.exoneraciones.length > 0) {
              this.ArancelModel.idExoneracion = this.exoneraciones[0].idExoneracion;
              this.ArancelModel.exonerado = Number(this.exoneraciones[0].exonerado);
            } else {
              this.ArancelModel.idExoneracion = null;
              this.ArancelModel.exonerado = null;
            }
            this.spinnerService.hide();
          }

        }, error => {
          this.spinnerService.hide();
        }, () => { });

      }

  }


    listadepositos(): void {
        this.spinnerService.show();
      this.http.get('../OrdenPagos/listadepositos', this.options).subscribe(data => {

            if (data.status == 200) {
                this.depositos = data.json().data;
                this.spinnerService.hide();
            }

        }, error => {
            this.spinnerService.hide();
            }, () => {

            });



    }

    listarecintos(): void {
        this.spinnerService.show();
      this.http.get('../OrdenPagos/listaRecintos', this.options).subscribe(data => {

            if (data.status == 200) {
               this.recintos = data.json().data;
                this.spinnerService.hide();
            }

        }, error => {
            this.spinnerService.hide();
            }, () => {

            });


    }


    numeroorden(): void {
        this.spinnerService.show();
        this.http.get('../OrdenPagos/maxorden?recintonum=' + this.OrdenModel.idRecinto, this.options).subscribe(data => {

            if (data.status == 200) {
                this.ultimaorden = Number(data.json().ordenpago) + 1;
                this.spinnerService.hide();
            }

        }, error => {
            this.spinnerService.hide();
        }, () => { });



    }

    guardar(nuevo: boolean): void {

       
        if (this.OrdenModel.recibimos.length > 0 && this.aranceltabla.length > 0 && Number(this.OrdenModel.idRecinto ) > 0 && this.OrdenModel.observacion.length <= 50) {
            this.errorgestionar = false;
            this.errorguardar = false;
            this.spinnerService.show();
            this.transac = true;

            if (nuevo) {
                this.http.post('../OrdenPagos/Create', JSON.stringify({

                    NoOrdenPago: this.ultimaorden, recintonum: this.OrdenModel.idRecinto
                    , Identificador: this.OrdenModel.tipoDeposito, IdTipoDeposito: this.OrdenModel.idTipoDeposito,
                    textoIdentificador: this.OrdenModel.textoIdentificador, EmailNotificacion : this.OrdenModel.emailNotificacion, Observacion : this.OrdenModel.observacion
                    , Aranceles: this.aranceltabla
                }), this.options).subscribe(data => {

                    if (data.status == 200) {

                        if (data.json().message == 'error') {
                            this.errorguardar = true;

                        } else {
                            this.SaveModel = new ordenpago();
                            this.OrdenModel = new ordenpago();
                            (<any>$('#OrdenModal')).modal('hide');
                            this.search();
                          
                        }

                        this.transac = false;

                    }

                }, error => {
                  this.transac = false;


                }, () => { this.spinnerService.hide(); });
            } else {
                this.http.post('../OrdenPagos/Edit', JSON.stringify({

                    IdOrdenPago: this.SaveModel.idOrdenPago, NoOrdenPago: this.SaveModel.noOrdenPago, Recibimos: this.OrdenModel.recibimos
                    , Identificador: this.OrdenModel.tipoDeposito, IdTipoDeposito: this.OrdenModel.idTipoDeposito,
                     textoIdentificador: this.OrdenModel.textoIdentificador, EmailNotificacion : this.OrdenModel.emailNotificacion,
                     Observacion : this.OrdenModel.observacion
                    , Aranceles: this.aranceltabla
                }), this.options).subscribe(data => {

                    if (data.status == 200) {

                        if (data.json().message == 'error') {
                            this.errorguardar = true;

                        } else {
                            this.SaveModel = new ordenpago();
                            this.OrdenModel = new ordenpago();
                            (<any>$('#OrdenModal')).modal('hide');
                            this.search();
                        }
                        this.transac = false;



                    }

                }, error => {

                    this.spinnerService.hide();
                    this.transac = false;

                }, () => { this.spinnerService.hide(); });
            }





        } else {
            this.errorgestionar = true;
        }


    }





    Gestionar(): void {
        if (this.nuevo) {
            this.guardar(true);
        } else {
            this.guardar(false);

        }
    }

    public selectestudiante(event: any, item: any) {

        this.OrdenModel.tipoDeposito = item.carnet;
        this.OrdenModel.recibimos = item.estudiante;
        this.OrdenModel.textoIdentificador = item.estudiante;
        this.EstudianteModel.carnet = item.carnet;


    }

    public selectestudianteme(event: any, item: any) {

      this.OrdenModel.tipoDeposito = item.carnet;
      this.OrdenModel.recibimos = item.estudiante;
      this.OrdenModel.textoIdentificador = item.estudiante;
      this.EstudianteMeModel.carnet = item.carnet;


  }

    public selecttrabajador(event: any, item: any) {

        this.OrdenModel.tipoDeposito = item.noEmpleado;
        this.OrdenModel.codigoempleado = item.noEmpleado;
        this.OrdenModel.recibimos = item.empleado;
        this.OrdenModel.textoIdentificador = item.empleado;
        // this.EmpleadoModel.cod_Interno = item.cod_Interno;


    }

    public selectproveedor(event: any, item: any) {

        this.OrdenModel.tipoDeposito = item.ruc;
        this.ProveedorModel.ruc = item.ruc;
        this.OrdenModel.textoIdentificador = item.razonSocial;
        this.OrdenModel.proveedor = item.razonSocial;



    }

    public selectagente(event: any, item: any) {

        this.OrdenModel.tipoDeposito = item.identificacion;
        this.OrdenModel.nombreagente = item.nombre;
        this.OrdenModel.recibimos = item.nombre;
        this.OrdenModel.textoIdentificador = item.nombre;
        this.AgenteModel.idAgenteExterno = item.idAgenteExterno;


    }

    public selectarea(event: any, item: any) {

      this.OrdenModel.tipoDeposito = item.codigo;
      this.OrdenModel.area = item.area;
      this.AreaModel.codigo = item.codigo;
      this.OrdenModel.recibimos = item.area;
      this.OrdenModel.textoIdentificador = item.area;


  }



    searchestudiante(): void {


        this.spinnerService.show();
        this.http.get('../OrdenPagos/EstudianteSIRA?_limit=10&_page=1&carnet=' + this.EstudianteModel.carnet + '&estudiante=' + this.EstudianteModel.estudiante + '&carrera=' + this.EstudianteModel.carrera + '&sede=' + this.EstudianteModel.sede, this.options).subscribe(data => {

            if (data.status == 200) {
                this.estudiantestabla = data.json().data;
                this.spinnerService.hide();
            }

        }, error => {
            this.spinnerService.hide();
            }, () => {

              });



    }

    searchestudianteme(): void {


      this.spinnerService.show();
      this.http.get('../OrdenPagos/EstudianteME?_limit=10&_page=1&carnet=' + this.EstudianteMeModel.carnet + '&estudiante=' + this.EstudianteMeModel.estudiante + '&estudia=' + this.EstudianteMeModel.estudia, this.options).subscribe(data => {

          if (data.status == 200) {
              this.estudiantesmetabla = data.json().data;
              this.spinnerService.hide();
          }

      }, error => {
          this.spinnerService.hide();
          }, () => {

            });



  }

    searchtrabajador(): void {


        this.spinnerService.show();
        this.http.get('../OrdenPagos/EmpleadoRH?_limit=10&_page=1&cod_interno=' + this.EmpleadoModel.cod_Interno + '&noEmpleado=' + this.EmpleadoModel.noEmpleado + '&empleado=' + this.EmpleadoModel.empleado + '&noCedula=' + this.EmpleadoModel.noCedula + '&area=' + this.EmpleadoModel.area, this.options).subscribe(data => {

            if (data.status == 200) {
                this.empleadostabla = data.json().data;
                this.spinnerService.hide();
            }

        }, error => {
            this.spinnerService.hide();
        }, () => {

        });



    }

    searchproveedor(): void {


        this.spinnerService.show();
        this.http.get('../OrdenPagos/ProveedoresSISCOM?_limit=10&_page=1&ruc=' + this.ProveedorModel.ruc + '&razonSocial=' + this.ProveedorModel.razonSocial + '&nombreComercial=' + this.ProveedorModel.nombreComercial + '&direccion=' + this.ProveedorModel.direccion + '&email=' + this.ProveedorModel.email, this.options).subscribe(data => {

            if (data.status == 200) {
                this.proveedortabla = data.json().data;
                this.spinnerService.hide();
            }

        }, error => {
            this.spinnerService.hide();
        }, () => {

        });



    }

    searchagente(): void {


        this.spinnerService.show();
        this.http.get('../OrdenPagos/AgentesExternos?_limit=10&_page=1&nombre=' + this.AgenteModel.nombre + '&identificacion=' + this.AgenteModel.identificador + '&procedencia=' + this.AgenteModel.direccion, this.options).subscribe(data => {

            if (data.status == 200) {
                this.agentetabla = data.json().data;
                this.spinnerService.hide();
            }

        }, error => {
            this.spinnerService.hide();
        }, () => {

        });



  }

  searcharea(): void {


    this.spinnerService.show();
    this.http.get('../OrdenPagos/AreasRRHH?_limit=10&_page=1&area=' + this.AreaModel.area + '&codigo=' + this.AreaModel.codigo, this.options).subscribe(data => {

        if (data.status == 200) {
            this.areatabla = data.json().data;
            this.spinnerService.hide();
        }

    }, error => {
        this.spinnerService.hide();
    }, () => {

    });



}




  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }


  export(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('selector'));
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Ordenes de Pago');
    /* save to file */
    XLSX.writeFile(wb, 'Ordenes de Pago Excel ' + new Date().toLocaleDateString() + '.xlsx');

  }


  export2(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('selector2'));
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Aranceles');
    /* save to file */
    XLSX.writeFile(wb, 'Aranceles Orden de Pago ' + this.SaveModel.noOrdenPago + new Date().toLocaleDateString() + '.xlsx');

  }



}




















