import { Component, Inject, OnInit, ViewChild, ElementRef,Input } from '@angular/core';
import { Http, Headers, RequestOptions, Response  } from '@angular/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Observable, interval} from 'rxjs';
import { Router, NavigationStart, Event } from '@angular/router';
import { DataService } from '../../_service/globalsvariable';
import { usuarioarancel } from '../../_class/usuarioarancel';
import { arancel } from '../../_class/arancel';
import { recinto } from '../../_class/recinto';
import { deposito } from '../../_class/deposito';

declare var $: any;

import { NgxMaskModule } from 'ngx-mask';
//import 'rxjs/add/operator/toPromise';

import * as XLSX from 'xlsx';




//import { ServerDataSource } from 'ng2-smart-table';
//import { OnInit } from '@angular/core/src/metadata/lifecycle_hooks';





/* @ViewChild('autocomplete1') autocomplete1; */

@Component({
    selector: 'ArancelesArea',
  templateUrl: './ArancelesArea.component.html',
  styleUrls: ['./ArancelesArea.component.css']

})



export class ArancelesAreaComponent implements OnInit {

  @ViewChild('autocomplete1', {static: false}) autocomplete1;
  keyword = 'empleado';
  notFoundtext = "No hay resultados";
    display = 'none';


  public data: arancel[];
  public data2: usuarioarancel[];
  public usuariosarea: usuarioarancel[];

  public tipodepositos: deposito[];
  public aranceles: arancel[];
  public recintos: recinto[];


    
      Tk: any;

  searchModel = new arancel();
  search2Model = new usuarioarancel();
  arancelModel = new arancel();


  SaveModel = new arancel();
  SaveModel2 = new usuarioarancel();
   
    nuevo: boolean;
    errorareas: boolean;
    errorgestionar: boolean;
    errorguardar: boolean = false;
    erroreliminar: boolean;
    errordepositos: boolean;
    selected: string;
    headers: any;
    Token: any;
    pagination: number = 10;
    pagination2: number = 10;
    pagination3: number = 10;
  
    options: any;
    timerbool= true;
    p1: number = 1;  
    p2: number = 1;
    p3: number = 1;
    timer: any;
    hashData: string = "";
    ngautocomplete1 :string = "";

  


  dataservice: DataService;
  pantalla: string = "ArancelesArea";
  permisoagregar: boolean = false;
  permisoeditar: boolean = false;
  permisoeliminar: boolean = false;
  permisoautorizar: boolean = false;
  permisoexcel: boolean = false;

   




  constructor(private http: Http, private spinnerService: Ng4LoadingSpinnerService, private router: Router, private dataserv: DataService) {


    this.dataservice = dataserv;
    this.searchModel.nombre = "";
    this.searchModel.descripcion = "";
    this.searchModel.cuenta = "";
    this.searchModel.fecha = "";
    this.searchModel.moneda = "";
    this.searchModel.precio = "";
    this.searchModel.tipo = "";
    this.searchModel.areabusqueda = "";
    this.searchModel.usuariocreacion = "";
    //this.searchModel2.area = "";
    //this.searchModel2.precio = "";
    //this.searchModel2.moneda = "";
    //this.searchModel2.recinto = "";
    //this.searchModel3.tipoDeposito = "";
    this.SaveModel.idArancel = 0;

    this.searchModel.simbolo = "";

        

       

        this.errorareas = false;
        this.errorgestionar = false;
        this.errorguardar = false;
        this.erroreliminar = false;
        this.errordepositos = false;
        this.nuevo = true;
    }

  ngOnInit() {

    var hd = new Headers();
    hd.append('Content-Type', 'application/json');
    var ops = new RequestOptions({ headers: hd });
    
  this.http.post('../Usuarios/tokenizador',  ops).subscribe(data => {
    if (data.status == 200) {
      this.Token = data.json().token;
      this.headers = new Headers();
      this.headers.append('Content-Type', 'application/json');
      this.headers.append('Authorization', 'Bearer ' + data.json().token);
      this.options = new RequestOptions({ headers: this.headers });
      //this.listadepositos();
      this.listarecintos();
     
      this.Modelchange();
      this.autorizados();
    }
    });


        interval(120000).subscribe(() => {
        if (this.timerbool) {
          this.Modelchange();
        }
      });

  
  }


  ngOnDestroy() {
    this.timerbool = false;
  }

    openmodal(nueo: boolean): void {

        this.nuevo = nueo;
        if (this.nuevo) {
            this.clear();
        } else {
            //this.areastabla = this.dataarea;
            //this.depositostabla = this.datadepositos;
        }

    }

    stopUpdate(): void{
        this.timer.dispose();
    }


    deletemodal(): void {

        (<any>$("#DeleteModal")).modal('show');



  }

  export(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById("selector"));
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Exoneraciones');
    /* save to file */
    XLSX.writeFile(wb, 'Exoneraciones Excel ' + new Date().toLocaleDateString() + '.xlsx');

  }


  autorizados() {
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.guardar, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoagregar = true;
      }

    }, error => {

      if (error.status == 401) {
        this.permisoagregar = false;
      }


    }

    );
    
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.eliminar, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoeliminar = true;
      }

    }, error => {

      if (error.status == 401) {
        this.permisoeliminar = false;
      }
    }

    );
   

    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.excel, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoexcel = true;
      }

    }, error => {

      if (error.status == 401) {
        this.permisoexcel = false;
      }
    });
  }


 


  //Gestionar(): void {
  //  this.errorgestionar = false;
  //      if (this.nuevo) {
  //          this.guardar(true);
  //      } else {
  //          this.guardar(false);
           
  //      }
  //  }



    public clear(): void
    {
         
      
      //this.tipodeposito = [];
      

      this.errorareas = false;
      this.errorguardar = false;
        
    }


    
    public selecttable(event: any, item: any) {

      this.SaveModel.idArancel = Number(item.idArancel);
      this.search2Model.idArancelArea = Number(item.idArancel);
      this.search2Model.empleado  = "";
      
       this.search2();
       this.listaempleadosarea( this.search2Model.idArancelArea);



    }

    public selecttable2(event: any, item: any) {

      this.SaveModel2.noInterno = (item.noInterno);
      this.SaveModel2.idUsuarioArancel = item.idUsuarioArancel;
     
      }

    selectusuarioarea(item): void {

      this.SaveModel2.noInterno = (item.noInterno);
      this.SaveModel2.usuario = item.usuario;
      this.SaveModel2.empleado = item.empleado;

    }

    agregartab(){
      this.errorgestionar = false;
      this.errorguardar = false;
      if(this.SaveModel2.usuario.length >0)
      {
          this.http.post('../ArancelesArea/Create', JSON.stringify({

            Usuario : this.SaveModel2.usuario, IdArancelArea: this.search2Model.idArancelArea
        }), this.options).subscribe(data => {

            if (data.status === 200) {

                if (data.json().message === 'error') {
                    this.errorguardar = true;

                } else {
                    this.SaveModel2 = new usuarioarancel();
                    this.SaveModel = new arancel();
                    this.listaempleadosarea(this.search2Model.idArancelArea);
                    this.search2();
                    this.autocomplete1.clear();
                  //  (<any>$("#ArancelAreaModal")).modal('hide');
                }



            }

        }, error => {
        }, () => { this.spinnerService.hide()});

      }else{
        this.errorgestionar = true;
      }
    }


    eliminartab(){
      this.errorgestionar = false;
      this.errorguardar = false;
      if(this.SaveModel2.idUsuarioArancel >0)
      {
          this.http.post('../ArancelesArea/Delete', JSON.stringify({

            IdUsuarioArancel : this.SaveModel2.idUsuarioArancel
        }), this.options).subscribe(data => {

            if (data.status === 200) {

                if (data.json().message === 'error') {
                    this.errorguardar = true;

                } else {
                    this.SaveModel2 = new usuarioarancel();
                    this.SaveModel = new arancel();
                    this.listaempleadosarea(this.search2Model.idArancelArea);
                    this.search2();
                    this.autocomplete1.clear();
                   // (<any>$("#ArancelAreaModal")).modal('hide');
                }



            }

        }, error => {
        }, () => { this.spinnerService.hide()});

      }else{
        this.errorgestionar = true;
      }
    }


     search():void {
        // this.spinnerService.show();

       this.http.get('../ArancelesArea/searchArancelesArea?_limit=' + this.pagination + '&_page=' + this.p1 + '&nombre=' + this.searchModel.nombre + '&descripcion=' + this.searchModel.descripcion + '&cuenta=' + this.searchModel.cuenta + '&fecha=' + this.searchModel.fecha + '&precio=' + this.searchModel.precio + '&moneda=' + this.searchModel.moneda + '&usuario=' + this.searchModel.usuariocreacion + '&tipo=' + this.searchModel.tipo , this.options).subscribe(data => {

             if (data.status == 200) {



                     this.data = data.json().data;
                    this.SaveModel.count = data.json().count;

             }

         }, error => {



             }, () => {
              //   this.spinnerService.hide();
             });


    }


  search2(): void {
    // this.spinnerService.show();

    this.http.get('../ArancelesArea/searchUsuariosArancel?_limit=' + this.pagination2 + '&_page=' + this.p2 + "&empleado=" + this.search2Model.empleado + "&arancel=" + String(this.search2Model.idArancelArea) +"&usuario=" + this.search2Model.usuario +"&nointerno=" + this.search2Model.noInterno  , this.options).subscribe(data => {

      if (data.status === 200) {

        this.data2 = data.json().data;
        this.SaveModel2.count = data.json().count;

      }


    }, error => {



    }, () => {
      //   this.spinnerService.hide();
    });



  }


    Modelchange(): void {
        // this.spinnerService.show();
     this.http.get('../ArancelesArea/ModelChangeArancel?_limit=' + this.pagination + '&_page=' + this.p1 + '&nombre='
      + this.searchModel.nombre + '&descripcion=' + this.searchModel.descripcion + '&cuenta=' 
     + this.searchModel.cuenta + '&fecha=' + this.searchModel.fecha + '&precio=' + this.searchModel.precio + '&moneda=' 
     + this.searchModel.moneda + '&usuario=' + this.searchModel.usuariocreacion + '&tipo=' + this.searchModel.tipo, this.options)
     .subscribe(data => {
     if (data.status == 200) {

                if (this.hashData != data.json().hash) {
                    this.hashData = data.json().hash;
                    this.search();

                }
                //this.spinnerService.hide();
            }


        }, error => {

            //  this.spinnerService.hide();

        }, () => {
            //this.spinnerService.hide();

        });





    }

   
    getPage(page: number) {
        this.p1 = page;
        this.search();

    }

   
  listadepositos(): void {
    // this.spinnerService.show();

    this.http.get('../ArancelesArea/listadepositos', this.options).subscribe(data => {
            if (data.status == 200) {



              this.tipodepositos = data.json();
           
        //this.tipodeposito.count = data.json().count;





      }


    }, error => {


        
    }, () => {
      //   this.spinnerService.hide();
    });



  }

 
  


  listarecintos(): void {
    this.spinnerService.show();
    this.http.get('../ArancelesArea/listaRecintos', this.options).subscribe(data => {

      if (data.status == 200) {
        this.recintos = data.json();
        this.spinnerService.hide();
      }

    }, error => {
      this.spinnerService.hide();
    }, () => {

    });


  }


  listaempleadosarea( arancelarea:number): void {
    this.spinnerService.show();
    this.http.get('../ArancelesArea/Empleados_Area?arancelarea=' + arancelarea, this.options).subscribe(data => {

      if (data.status == 200) {
        this.usuariosarea = data.json();
        this.spinnerService.hide();
      }

    }, error => {
      this.spinnerService.hide();
    }, () => {

    });


  }


    private extractData(res: Response) {
        let body = res.json();
        return body || {};
    }

    private handleErrorObservable(error: Response | any) {
     
        return Observable.throw(error.message || error);
    }

   
}








