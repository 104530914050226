import { Component, Inject, OnInit, ViewChild, ElementRef,Input } from '@angular/core';
import { Http, Headers, RequestOptions, Response  } from '@angular/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Observable, interval} from 'rxjs';
import { Router, NavigationStart, Event } from '@angular/router';
import { DataService } from '../../_service/globalsvariable';
import { Exoneracion as exoneracion} from '../../_class/exoneraciontotalarea';
import { arancel } from '../../_class/arancel';
import { recinto } from '../../_class/recinto';
import { deposito } from '../../_class/deposito';
declare var $: any;


import { NgxMaskModule } from 'ngx-mask';
//import 'rxjs/add/operator/toPromise';

import * as XLSX from 'xlsx';
import { arancelarea } from 'src/app/_class/arancelarea';


//import { ServerDataSource } from 'ng2-smart-table';
//import { OnInit } from '@angular/core/src/metadata/lifecycle_hooks';





@Component({
    selector: 'exoneraciontotalarea',
  templateUrl: './exoneracion.component.html',
  styleUrls: ['./exoneracion.component.css']
  

})


export class ExoneracionTotalAreaComponent implements OnInit {


  keyword = 'nombre';
  notFoundtext = "No hay resultados";
    display = 'none';
    

  public data: exoneracion[];

  public tipodepositos: deposito[];
  public aranceles: arancel[];
  public recintos: recinto[];

    
      Tk: any;

  searchModel = new exoneracion();
  arancelModel = new arancel();
  ExoneracionModel = new exoneracion();

    

   
    nuevo: boolean;
    errorareas: boolean;
    errorgestionar: boolean;
    errortipo:boolean = false;
    errorguardar: boolean;
    erroreliminar: boolean;
    errordepositos: boolean;
    errorexiste: boolean;
    selected: string;
    headers: any;
    Token: any;
    pagination: number = 10;
    pagination2: number = 10;
    pagination3: number = 10;
  
    options: any;
    timerbool= true;
    p1: number = 1;  
    p2: number = 1;
    p3: number = 1;
    timer: any;
    hashData: string = "";

  
  SaveModel = new exoneracion();


  dataservice: DataService;
  pantalla: string = "ExoneracionTotalPorArea";
  permisoagregar: boolean = false;
  permisoeliminar: boolean = false;
  permisoexcel: boolean = false;
  areas: arancelarea[];

   




  constructor(private http: Http, private spinnerService: Ng4LoadingSpinnerService, private router: Router, private dataserv: DataService) {


    this.dataservice = dataserv;
   
        this.errorareas = false;
        this.errorgestionar = false;
        this.errorguardar = false;
        this.erroreliminar = false;
        this.errordepositos = false;
        this.nuevo = true;

    }

  ngOnInit() {

    var hd = new Headers();
    hd.append('Content-Type', 'application/json');
    var ops = new RequestOptions({ headers: hd });
    
  this.http.post('../Usuarios/tokenizador',  ops).subscribe(data => {
    if (data.status == 200) {
      this.Token = data.json().token;
      this.headers = new Headers(); 
      this.headers.append('Content-Type', 'application/json');
      this.headers.append('Authorization', 'Bearer ' + data.json().token);
      this.options = new RequestOptions({ headers: this.headers });
     
      this.autorizados();
      this.search();
      this.listaareas();
     
    }
    });

  }



    openmodal(nueo: boolean): void {
        
        this.nuevo = nueo;
        if (this.nuevo) {
            this.clear();
           
        } else {
            //this.areastabla = this.dataarea;
            //this.depositostabla = this.datadepositos;
            
        }
        
      
       
    }

    


    deletemodal(): void {
           
        (<any> $("#DeleteModal")).modal('show');



  }

  export(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById("selector"));
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Exoneraciones');
    /* save to file */
    XLSX.writeFile(wb, 'Exoneraciones Excel ' + new Date().toLocaleDateString() + '.xlsx');

  }


  autorizados() {
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.guardar, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoagregar = true;
      }

    }, error => {

      if (error.status == 401) {
        this.permisoagregar = false;
      }


    }

    );
    
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.eliminar, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoeliminar = true;
      }

    }, error => {

      if (error.status == 401) {
        this.permisoeliminar = false;
      }
    }

    );   

    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.excel, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoexcel = true;
      }

    }, error => {

      if (error.status == 401) {
        this.permisoexcel = false;
      }
    });
  }

  Gestionar(): void {
    this.errorgestionar = false;
      if (this.nuevo) {
        this.ejecutar(true);
      } else {
        this.ejecutar(false);
           
       }
   }



    public clear(): void
    {
         
      
      //this.tipodeposito = [];
      this.ExoneracionModel = new exoneracion;

      this.errorareas = false;
      this.errorguardar = false;
      this.errortipo = false;
        
    }


    
    public selecttable(event: any, item: any) {
    
      this.SaveModel.idExoneracionTotalArea = item.idExoneracionTotalArea;
      this.SaveModel.idArea = item.idArea;
      this.SaveModel.area = item.area + "";
      this.SaveModel.usuarioCreacion = item.usuarioCreacion;
      this.SaveModel.fechaCreacion = item.fechaCreacion;

      this.ExoneracionModel.idExoneracionTotalArea = item.idExoneracionTotalArea;
      this.ExoneracionModel.idArea = item.idArea;
      this.ExoneracionModel.area = item.area + "";
      this.ExoneracionModel.usuarioCreacion = item.usuarioCreacion + "";;
      this.ExoneracionModel.fechaCreacion = item.fechaCreacion + "";;

    }


     search():void {
        // this.spinnerService.show();

       this.http.get('../ExoneracionTotalArea/search?_limit=' + this.pagination + '&_page=' + this.p1 + '&area=' + this.searchModel.area , this.options).subscribe(data => {

             if (data.status == 200) {
                     this.data = data.json().data;
                     this.SaveModel.count = data.json().count;

             }

         }, error => {



             }, () => {
              //   this.spinnerService.hide();
             });



    }



   
    getPage(page: number) {
        this.p1 = page;
        this.search();

    }



 
    ejecutar(nuevo: boolean): void {
 
      if (this.ExoneracionModel.idArea> 0 ) {
              this.errorgestionar = false;
              this.errorguardar = false;
              this.spinnerService.show();
  
  
              if (nuevo) {
                this.http.post('../ExoneracionTotalArea/Create', JSON.stringify({                

                  IdArea: this.ExoneracionModel.idArea

                  }), this.options).subscribe(data => {
  
                      if (data.status === 200) {
  
                          if (data.json().message === 'error') {
                              this.errorguardar = true;
                          } else {
                              (<any>$("#ExoneracionModal")).modal('hide');
                            this.search();
                            this.listaareas();

                          }


                        }
  
                  }, error => {
  
  
  
                  }, () => { this.spinnerService.hide()});
              }
  
  
  
  
  
          } else {
              this.errorgestionar = true;

          }
      }
  


    eliminar(): void {
      if (this.SaveModel.idExoneracionTotalArea > 0) {
          this.erroreliminar = false;
          this.spinnerService.show();

          this.http.post('../ExoneracionTotalArea/Delete', JSON.stringify({
            IdExoneracionTotalArea: this.SaveModel.idExoneracionTotalArea
          }), this.options).subscribe(data => {

              if (data.status === 200) {

                  if (data.json().message === 'error') {
                    this.erroreliminar = true;
                      this.spinnerService.hide();
                  } else
                      {
                    (<any>$("#DeleteModal")).modal('hide');
                      this.spinnerService.hide();
                    this.search();
                    this.listaareas();
                    //this.listaareasconaranceles();
                   

                      }
              }

          }, error => {



          }, () => {  });

      }

  }

  listaareas(): void {
    this.spinnerService.show();
    this.http.get('../ExoneracionTotalArea/listaareas', this.options).subscribe(data => {

        if (data.status == 200) {
          this.areas = data.json();
            this.spinnerService.hide();
        }

    }, error => {
        this.spinnerService.hide();
    }, () => { });
    this.spinnerService.hide();

}
selectarea (event:any){
  this.ExoneracionModel.idArea = event.codigo;
}

    private extractData(res: Response) {
        let body = res.json();
        return body || {};
    }

    private handleErrorObservable(error: Response | any) {
       
        return Observable.throw(error.message || error);
    }

   
}








