import { Component, Inject, OnInit, ViewChild, ElementRef,Input } from '@angular/core';
import { Http, Headers, RequestOptions, Response  } from '@angular/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Observable, interval} from 'rxjs';
import { Router, NavigationStart, Event } from '@angular/router';
import { DataService } from '../../_service/globalsvariable';
import { exoneracion } from '../../_class/exoneracion';
import { arancel } from '../../_class/arancel';
import { recinto } from '../../_class/recinto';
import { deposito } from '../../_class/deposito';
declare var $: any;


import { NgxMaskModule } from 'ngx-mask';
//import 'rxjs/add/operator/toPromise';

import * as XLSX from 'xlsx';


//import { ServerDataSource } from 'ng2-smart-table';
//import { OnInit } from '@angular/core/src/metadata/lifecycle_hooks';





@Component({
    selector: 'exoneracion',
  templateUrl: './exoneracion.component.html',
  styleUrls: ['./exoneracion.component.css']
  

})


export class ExoneracionComponent implements OnInit {

  @ViewChild('autocomplete1', {static: false}) autocomplete1;
 public autocomplete1string: string ;

  keyword = 'complete';
  notFoundtext = "No hay resultados";
    display = 'none';
    

  public data: exoneracion[];

  public tipodepositos: deposito[];
  public aranceles: arancel[];
  public recintos: recinto[];


    
      Tk: any;

  searchModel = new exoneracion();
  arancelModel = new arancel();
  ExoneracionModel = new exoneracion();

    

   
    nuevo: boolean;
    errorareas: boolean;
    errorgestionar: boolean;
    errortipo:boolean = false;
    errorguardar: boolean;
    erroreliminar: boolean;
    errordepositos: boolean;
    errorexiste: boolean;
    selected: string;
    headers: any;
    Token: any;
    pagination: number = 10;
    pagination2: number = 10;
    pagination3: number = 10;
  
    options: any;
    timerbool= true;
    p1: number = 1;  
    p2: number = 1;
    p3: number = 1;
    timer: any;
    hashData: string = "";

  
  SaveModel = new exoneracion();


  dataservice: DataService;
  pantalla: string = "Exoneracion";
  permisoagregar: boolean = false;
  permisoeditar: boolean = false;
  permisoeliminar: boolean = false;
  permisoautorizar: boolean = false;
  permisoexcel: boolean = false;
  areasexoneracion: any;

   




  constructor(private http: Http, private spinnerService: Ng4LoadingSpinnerService, private router: Router, private dataserv: DataService) {


    this.dataservice = dataserv;
    this.searchModel.identificador = "";
    this.searchModel.noOrdenPago = "";
    this.searchModel.fechaCreacion = "";
    this.searchModel.usuarioCreacion = "";
    this.searchModel.tipoDeposito = "";
    this.searchModel.autorizadopor = "";
    this.searchModel.exonerado = "";
    this.searchModel.arancel = "";
    this.searchModel.idArancelPrecio = "";
    this.searchModel.idMoneda = 0;
    this.searchModel.simbolo = "";
    this.ExoneracionModel.identificador = "";

    this.SaveModel.idExoneracion = 0;

        this.errorareas = false;
        this.errorgestionar = false;
        this.errorguardar = false;
        this.erroreliminar = false;
        this.errordepositos = false;
        this.nuevo = true;

       

    
        
    }

  ngOnInit() {

    var hd = new Headers();
    hd.append('Content-Type', 'application/json');
    var ops = new RequestOptions({ headers: hd });
    
  this.http.post('../Usuarios/tokenizador',  ops).subscribe(data => {
    if (data.status == 200) {
      this.Token = data.json().token;
      this.headers = new Headers();
      this.headers.append('Content-Type', 'application/json');
      this.headers.append('Authorization', 'Bearer ' + data.json().token);
      this.options = new RequestOptions({ headers: this.headers });
      this.listadepositos();
      this.listarecintos();
      this.Modelchange();
      this.autorizados();
      this.listaareasexoneracion();
     
    }
    });


        interval(120000).subscribe(() => {
        if (this.timerbool) {
          this.Modelchange();
        }
      });

    

 
  }


  ngOnDestroy() {
    this.timerbool = false;
  }

    openmodal(nueo: boolean): void {
        
        this.nuevo = nueo;
        if (this.nuevo) {
            this.clear();
           
        } else {
            //this.areastabla = this.dataarea;
            //this.depositostabla = this.datadepositos;
            
        }
        
      
       
    }

  

    stopUpdate(): void{
        this.timer.dispose();
    }


    deletemodal(): void {
           
        (<any> $("#DeleteModal")).modal('show');



  }

  export(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById("selector"));
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Exoneraciones');
    /* save to file */
    XLSX.writeFile(wb, 'Exoneraciones Excel ' + new Date().toLocaleDateString() + '.xlsx');

  }


  autorizados() {
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.guardar, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoagregar = true;
      }

    }, error => {

      if (error.status == 401) {
        this.permisoagregar = false;
      }


    }

    );
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.editar, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoeditar = true;
      }

    }, error => {

      if (error.status == 401) {
        this.permisoeditar = false;
      }
    });
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.eliminar, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoeliminar = true;
      }

    }, error => {

      if (error.status == 401) {
        this.permisoeliminar = false;
      }
    }

    );
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.autorizar, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoautorizar = true;
      }

    }, error => {

      if (error.status == 401) {
        this.permisoautorizar = false;
      }
    });

    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.excel, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoexcel = true;
      }

    }, error => {

      if (error.status == 401) {
        this.permisoexcel = false;
      }
    });
  }


 


  Gestionar(autorizar:boolean): void {
    this.errorgestionar = false;
      if (this.nuevo) {
        this.ejecutar(true,autorizar);
      } else {
        this.ejecutar(false,autorizar);
           
       }
   }



    public clear(): void
    {
         
      
      //this.tipodeposito = [];
      this.ExoneracionModel.idExoneracion = 0;
      this.ExoneracionModel.exonerado = "";
      this.ExoneracionModel.identificador = "";
      this.ExoneracionModel.noOrdenPago = "";
      this.ExoneracionModel.fechaCreacion = "";
      this.ExoneracionModel.usuarioCreacion = "";
      this.ExoneracionModel.tipoDeposito = "";
      this.ExoneracionModel.autorizadopor = "";
      this.ExoneracionModel.exonerado = "";
      this.ExoneracionModel.idArancelPrecio = "";
      this.ExoneracionModel.arancel = "";
      this.ExoneracionModel.idMoneda = 0;
      this.ExoneracionModel.simbolo = "";

      this.errorareas = false;
      this.errorguardar = false;
      this.errortipo = false;
        
    }


    
    public selecttable(event: any, item: any) {
    
      this.SaveModel.idExoneracion = item.idExoneracion;
      this.SaveModel.exonerado = item.exonerado;
      this.SaveModel.identificador = item.identificador + "";
      this.SaveModel.tipoDeposito = item.tipoDeposito;
      this.SaveModel.idTipoDeposito = item.idTipoDeposito;
      this.SaveModel.usuarioCreacion = item.usuarioCreacion;
      this.SaveModel.fechaCreacion = item.fechaCreacion;
      this.SaveModel.autorizadopor = item.autorizadopor;
      this.SaveModel.idArancelPrecio = item.idArancelPrecio;
      this.SaveModel.arancel = item.arancel;
      this.SaveModel.noOrdenPago = item.noOrdenPago;
  
      this.ExoneracionModel.idMoneda = item.idMoneda;
      this.ExoneracionModel.simbolo = item.simbolo;
      this.ExoneracionModel.idExoneracion = this.SaveModel.idExoneracion;
      this.ExoneracionModel.exonerado = this.SaveModel.exonerado + "";
      this.ExoneracionModel.identificador = item.identificador;
      this.ExoneracionModel.idTipoDeposito = this.SaveModel.idTipoDeposito ;
      this.ExoneracionModel.fechaCreacion = String(this.SaveModel.fechaCreacion);
      this.ExoneracionModel.autorizadopor = this.SaveModel.autorizadopor + "";
      this.ExoneracionModel.arancel = (this.SaveModel.arancel);
      this.ExoneracionModel.noOrdenPago = String(this.SaveModel.noOrdenPago);
      this.ExoneracionModel.tipoprecio = item.tipoprecio;
      this.ExoneracionModel.precio = item.precio;
      this.ExoneracionModel.idArancelPrecio = this.SaveModel.idArancelPrecio;
      this.ExoneracionModel.idExoneracion = item.idExoneracion;

      this.autocomplete1string = item.tipoprecio==true? item.arancel + " - " +item.simbolo+" Variable":  item.arancel + " - " +item.simbolo+" "+item.precio ;
      
      this.listaaranceles(false);
    }
    

    
  selectarancel(item): void {


    this.ExoneracionModel.idMoneda = item.idMoneda;
    this.ExoneracionModel.simbolo = item.simbolo;
    this.ExoneracionModel.tipoprecio = item.tipoprecio;
    this.ExoneracionModel.idArancelPrecio = item.idArancelPrecio;
    this.ExoneracionModel.precio = item.precio;
  }


     search():void {
        // this.spinnerService.show();

       this.http.get('../Exoneracion/searchExoneraciones?_limit=' + this.pagination + '&_page=' + this.p1 + '&ordenpago=' + this.searchModel.noOrdenPago + '&identificador=' + this.searchModel.identificador + '&autorizado=' + this.searchModel.autorizadopor + '&fecha=' + this.searchModel.fechaCreacion + '&exonerado=' + this.searchModel.exonerado + '&usuario=' + this.searchModel.usuarioCreacion + '&tipo=' + this.searchModel.idTipoDeposito, this.options).subscribe(data => {

             if (data.status == 200) {
                     this.data = data.json().data;                   
                     this.SaveModel.count = data.json().count;

             }

         }, error => {



             }, () => {
              //   this.spinnerService.hide();
             });



    }



    Modelchange(): void {
        // this.spinnerService.show();
      this.http.get('../Exoneracion/ModelChangeExoneracion?_limit=' + this.pagination + '&_page=' + this.p1 + '&ordenpago=' + this.searchModel.noOrdenPago + '&identificador=' + this.searchModel.identificador + '&autorizado=' + this.searchModel.autorizadopor + '&fecha=' + this.searchModel.fechaCreacion + '&exonerado=' + this.searchModel.exonerado + '&usuario=' + this.searchModel.usuarioCreacion, this.options).subscribe(data => {
     if (data.status == 200) {

                if (this.hashData !== data.json().hash) {
                    this.hashData = data.json().hash;
                    this.search();

                }
                //this.spinnerService.hide();
            }


        }, error => {

            //  this.spinnerService.hide();

        }, () => {
            //this.spinnerService.hide();

        });





    }

   
    getPage(page: number) {
        this.p1 = page;
        this.search();

    }

   
  listadepositos(): void {
    // this.spinnerService.show();

    this.http.get('../Exoneracion/listadepositos', this.options).subscribe(data => {
            if (data.status == 200) {



              this.tipodepositos = data.json();
           
        //this.tipodeposito.count = data.json().count;





      }


    }, error => {


        
    }, () => {
      //   this.spinnerService.hide();
    });



  }

  existeIdentificar(){
    
    this.http.get('../Exoneracion/ExisteIdentificador?identificador='+this.ExoneracionModel.identificador+"&tipo="+this.ExoneracionModel.idTipoDeposito, this.options).subscribe(data => {

      this.errorexiste = data.json();
    
    }, error => {

     this.errorexiste = false;
    });
  }

  listaaranceles(isexist:boolean): void {

    if(Number(this.ExoneracionModel.idTipoDeposito) > 0){
        
    }
    if(isexist){
      this.ExoneracionModel.identificador = "";
      this.errorexiste = null;
    }else{
      this.errorexiste = true;
    }
   
   
    if ( Number(this.ExoneracionModel.idTipoDeposito) > 0) {
        this.spinnerService.show();
        this.http.get('../Exoneracion/listaaranceles?deposito=' + this.ExoneracionModel.idTipoDeposito, this.options).subscribe(data => {

          if (data.status == 200) {
            this.aranceles = data.json();
            this.spinnerService.hide();
            if(isexist){
            this.autocomplete1.clear();
            }
          }

        }, error => {
          this.spinnerService.hide();
        }, () => { });

      }
   else {
        this.spinnerService.show();
        this.http.get('../Exoneracion/listaaranceles?deposito=' + this.ExoneracionModel.idTipoDeposito, this.options).subscribe(data => {

          if (data.status == 200) {
            this.aranceles = data.json();
            this.spinnerService.hide();
          }

        }, error => {
          this.spinnerService.hide();
        }, () => { });


      }
    }

  


  listarecintos(): void {
    this.spinnerService.show();
    this.http.get('../Exoneracion/listaRecintos', this.options).subscribe(data => {

      if (data.status == 200) {
        this.recintos = data.json();
        this.spinnerService.hide();
      }

    }, error => {
      this.spinnerService.hide();
    }, () => {

    });


  }
  listaareasexoneracion(): void {
    this.spinnerService.show();
    this.http.get('../Exoneracion/listaareasexoneracion', this.options).subscribe(data => {

      if (data.status == 200) {
        this.areasexoneracion = data.json();
        this.spinnerService.hide();
      }

    }, error => {
      this.spinnerService.hide();
    }, () => {

    });


  }



  ejecutar(nuevo: boolean, autorizar: boolean): void {
 
  if((this.ExoneracionModel.tipoprecio==false && Number(this.ExoneracionModel.exonerado) < Number(this.ExoneracionModel.precio) || this.ExoneracionModel.tipoprecio)){
        this.errortipo = false;
      
     
    if ( Number(this.ExoneracionModel.idArancelPrecio) >0 && Number(this.ExoneracionModel.exonerado )> 0 && this.ExoneracionModel.identificador.length>0 && this.errorexiste) {
            this.errorgestionar = false;
            this.errorguardar = false;
            this.spinnerService.show();


            if (nuevo) {
              if(autorizar){
                this.http.post('../Exoneracion/CreateConAutorizacion', JSON.stringify({

                  Identificador : this.ExoneracionModel.identificador, idTipoDeposito: this.ExoneracionModel.idTipoDeposito,
                  IdArancelPrecio : this.ExoneracionModel.idArancelPrecio, Exonerado : this.ExoneracionModel.exonerado
  
                  }), this.options).subscribe(data => {
  
                      if (data.status == 200) {
  
                          if (data.json().message == 'error') {
                              this.errorguardar = true;
  
                          } else {
                              (<any>$("#ExoneracionModal")).modal('hide');
                            this.search();
                            this.ExoneracionModel = new exoneracion;
                          }
                      }
  
                  }, error => {
                  }, () => { this.spinnerService.hide() });

              }else{
                this.http.post('../Exoneracion/CreateSinAutorizacion', JSON.stringify({

                  Identificador : this.ExoneracionModel.identificador, idTipoDeposito: this.ExoneracionModel.idTipoDeposito,
                  IdArancelPrecio : this.ExoneracionModel.idArancelPrecio, Exonerado : this.ExoneracionModel.exonerado
  
                  }), this.options).subscribe(data => {
  
                      if (data.status == 200) {
  
                          if (data.json().message == 'error') {
                              this.errorguardar = true;
  
                          } else {
                              (<any>$("#ExoneracionModal")).modal('hide');
                            this.search();
                            this.ExoneracionModel = new exoneracion;
                          }
                      }
  
                  }, error => {
                  }, () => { this.spinnerService.hide() });
              }
            } else {
              this.http.post('../Exoneracion/Update', JSON.stringify({
                IdExoneracion : this.ExoneracionModel.idExoneracion,
                Identificador : this.ExoneracionModel.identificador, idTipoDeposito: this.ExoneracionModel.idTipoDeposito,
                IdArancelPrecio : this.ExoneracionModel.idArancelPrecio, Exonerado : this.ExoneracionModel.exonerado

                }), this.options).subscribe(data => {

                    if (data.status == 200) {

                        if (data.json().message == 'error') {
                            this.errorguardar = true;

                        } else {
                            (<any>$("#ExoneracionModal")).modal('hide');
                          this.search();
                         
                        }



                    }

                }, error => {



                }, () => { this.spinnerService.hide() });
            }





        } else {
            this.errorgestionar = true;
         
        }
      } else {
        this.errortipo = true;
      
      }
    }


    eliminar(): void {
      if (this.SaveModel.idExoneracion > 0) {
          this.erroreliminar = false;
          this.spinnerService.show();

          this.http.post('../Exoneracion/Delete', JSON.stringify({
              IdExoneracion: this.SaveModel.idExoneracion                
          }), this.options).subscribe(data => {

              if (data.status == 200) {

                  if (data.json().message == 'error') {
                    this.erroreliminar = true;
                    
                      this.spinnerService.hide()
                  } else
                      {
                    (<any>$("#DeleteModal")).modal('hide');
                      this.spinnerService.hide()
                    this.search();
                    //this.listaareasconaranceles();
                   

                      }
              }

          }, error => {



          }, () => {  });

      }

  }
  Autorizar(): void {
    if (this.SaveModel.idExoneracion > 0) {
        this.erroreliminar = false;
        this.spinnerService.show();

        this.http.post('../Exoneracion/Autorizar', JSON.stringify({
            IdExoneracion: this.SaveModel.idExoneracion                
        }), this.options).subscribe(data => {

            if (data.status == 200) {

                if (data.json().message == 'error') {
                  this.erroreliminar = true;
                  
                    this.spinnerService.hide()
                } else
                    {
                  (<any>$("#AutorizarModal")).modal('hide');
                    this.spinnerService.hide()
                  this.search();
                  //this.listaareasconaranceles();
                 

                    }
            }

        }, error => {



        }, () => {  });

    }

}    

    private extractData(res: Response) {
        let body = res.json();
        return body || {};
    }

    private handleErrorObservable(error: Response | any) {
       
        return Observable.throw(error.message || error);
    }

   
}








