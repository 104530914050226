export class agente {
  idAgenteExterno?: number = 0;
  ruc?: string = "";
  nombre?: string = "";
  identificador?:string = "";
  tipoidentificador?:string="";
  cedula?: string = "";
  direccion?: string = "";
  contacto?: string = "";
  telefono?: string = "";
  fax?: string = "";
  email?: string = "";
  sitioWeb?: string = "";
  idMunicipio?: number = 0;
  municipio?: string = "";
  procedencia?: string ="";
  usuariocreacion?:string="";
  fechacreacion?:string="";
  identificacion?:string="";
  idIdentificacion?:string ="";
  identi:string="";

  count?: number = 0;
}
