import { Component, OnInit } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Router, NavigationStart, Event } from '@angular/router';
import { DataService } from '../../_service/globalsvariable';
import { pantalla } from '../../_class/pantalla';

declare var $: any;
@Component({
    selector: 'nav-menu',
    templateUrl: './navmenu.component.html',
    styleUrls: ['./navmenu.component.css']
})
export class NavMenuComponent implements OnInit{
  constructor(
    private router: Router, private http: Http, dataservice: DataService
  ) {
    this.logouni = dataservice.imagenlogo;
    this.User = dataservice.User;
    this.Area = dataservice.Area;
    this.isAuthorization = false;  
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Authorization', 'Bearer ' + dataservice.Token);
    this.options = new RequestOptions({ headers: this.headers });
    if (dataservice.Token != null || dataservice.Token != undefined) {
      this.isAuthorization = true;

      router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
         
       


        }
      });
    }
       
    }
    menunav: string;
    headers: any;
  options: any;
  isAuthorization: boolean;
  logouni: string;
  User: string;
  Area: string;
 

    pantallas:  pantalla[];

    
  closemenu() {
  
    (<any>$('#menusis')).addClass('animatemenu');
    (<any>$('#ruleta')).addClass('animatemain');
    (<any>$('#menusis')).removeClass('aniopenmenu'); 
    (<any>$('#ruleta')).removeClass('aniclosemain');
    (<any>$('#navlarge')).addClass('fadeOut');
    (<any>$('#navlarge')).removeClass('fadeIn');
    
    setTimeout(function () {
      (<any>$('#mainsis')).removeClass('row');
      (<any>$('#menusis')).removeClass('col-3');
    
     
      
      (<any>$('#navlarge')).removeClass('d-none');  
      
      (<any>$('#ruleta')).addClass('col-12');
    }, 300);

    setTimeout(function () {
      (<any>$('#menusis')).addClass('d-none');  
      (<any>$('#menusis')).removeClass('animatemenu');
      (<any>$('#ruleta')).removeClass('animatemain');
    }, 300);
    
  }

  
    logout() {

      this.http.post('../Usuarios/logout', null, this.options).subscribe(data => {
        if (data.status == 200) {
          if (data.json().message) {
          window.location.href = "https://login.windows.net/common/oauth2/logout?post_logout_redirect_uri=https://op.uni.edu.ni";
          }
        }

      });
     
    }

    printer(op:any): void{
      

        
  }




  ngOnInit() {

    this.menunav = "";

    

    if (this.isAuthorization) {
      //this.printer(this.options);
      this.http.post('../Usuarios/menu',null, this.options).subscribe(data => {
        if (data.status == 200) {
          this.pantallas = data.json().pantallas;
          
        }

      });

    }else{
    
    }
 

    

  }
}


