export class arancel {


  idarancel: number;
  nombre: string;
  descripcion: string;
  idprecio: number;
  precio: string;
  idmoneda: number;
  moneda: string;
  idcuenta: number;
  idtipo: number;
  tipo: string;
  cuenta: string;
  fecha: string;
  usuariocreacion: string;
  reganulado: boolean;
  idrecinto: number;
  nombrecompleto: string;
  siglas: string;
  count: number;
  idArancel?:number
  isPrecioVariable: boolean;
  areabusqueda?: string = "0";
  recintobusqueda?: string = "0";
  simbolo?: string;

}   
