import { Component, OnInit } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
// import { Router } from '@angular/router';

import { DataService } from '../../_service/globalsvariable';

import { ordenarancelrep } from '../../_class/ordenarancel';
import { reciborep } from '../../_class/recibo';
import { formapagorep } from '../../_class/formapago';
import * as $ from 'jquery';
import jspdf from 'jspdf';
import 'jspdf-autotable';
import { exoneracion } from 'src/app/_class/exoneracion';







@Component({
  selector: 'reporte',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.css']
})
export class ReporteComponent implements OnInit {
  logouni: string;
  dse: DataService;
  reporte: any;
  reporteexo: any;
  reporteordenes: any;
  options: any;
  http: any;
  fechafinal: any;
  fechainicio: any;
  pdfSrc: any;
  pdf: any;
  errorreporte1: string;
  errorreporte3: string;
  fechafinalexo: any;
  fechainicioexo: any;
  pdfSrcexo: any;
  pdfexo: any;
  pdfSrcordenes: any;
  errorreporte2: string;
  arancelexo: string = "";
  monedaexo: string = "";

  aranceles: ordenarancelrep[];
  exoneraciones: any = [];
  ordenes: any[];

  arancelesarea: any[];
  recibos: reciborep[];
  pantalla: string = "reportes";

  permisoimprimir = false;
  permisoverinforme = false;
  monedas: any[];
  fechafinalordenes: any;
  fechainicioordenes: any;

  pdfordenes: any;

  constructor(
    dataservice: DataService, http: Http,
    private spinnerService: Ng4LoadingSpinnerService
  ) {

    this.dse = dataservice;
    this.http = http;

  }


  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    bezierCurve : false,
    onComplete: null
  };
  public barChartLabels = ['Dolares', 'Cordobas'];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    { data: [0, 0], label: 'Anulados' },
    { data: [0, 0], label: 'Cancelados' },
    { data: [0, 0], label: 'Generados' }
    
  ];

  ngOnInit(): void {
    this.logouni = this.dse.logouni;
    (<any>$('#multiCollapseingreso')).addClass('collpasing');
    (<any>$('#multiCollapseingreso')).addClass('show');
    // tslint:disable-next-line: comment-format
    //Buscando permisos en la api
    setTimeout(() => {
      this.autorizados();

      this.http.get('../Reportes/Aranceles', this.options).subscribe(data => {
        if (data.status == 200) {
          this.arancelesarea = data.json().data;
        }
      });

      this.http.get('../Reportes/listamonedas', this.options).subscribe(data => {

        if (data.status == 200) {
          this.monedas = data.json();
          // this.spinnerService.hide();
        }

      });

    }, 3000);



  }

  public autorizados() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.dse.Token);

    this.options = new RequestOptions({ headers: headers });
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dse.verinforme, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoverinforme = true;
      }

    }, error => {

      if (error.status == 401) {
        this.permisoverinforme = false;
      }


    }

    );

    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dse.imprimir, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoimprimir = true;
      }

    }, error => {

      if (error.status == 401) {
        this.permisoimprimir = false;
      }


    }

    );
  }
  // tslint:disable-next-line: comment-format
  //Metodo de comprobacion antes de generar el reporte
  public clickgen() {
    if (this.fechafinal != undefined && this.fechainicio != undefined && this.fechainicio != null && this.fechafinal != null) {
      this.reporteg();


    } else {

      this.errorreporte1 = "Debe seleccionar una fecha inicial y final para generar el reporte.";

    }
  }

  public reporteg() {

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.dse.Token);

    this.options = new RequestOptions({ headers: headers });
    this.spinnerService.show();
    this.http.get('../Reportes/OrdenesPagadas?FechaFin=' + this.fechafinal + '&FechaInicio=' + this.fechainicio, this.options).subscribe(data => {
      if (data.status == 200) {
        this.aranceles = data.json().data;
   
        this.http.get('../Reportes/RecibosPagados?FechaFin=' + this.fechafinal + '&FechaInicio=' + this.fechainicio, this.options).subscribe(data => {
          if (data.status == 200) {

            this.recibos = data.json().data;

          }

          if (this.recibos.length != 0) {
            this.errorreporte1 = "";
            this.captureScreen();
          } else {
            this.errorreporte1 = "No hay ingresos en ese periodo";
          }
         
        });
        this.spinnerService.hide();
      }
    });
  }


  public captureScreen() {

    var totalareac = 0;
    var totalaread = 0;
    var totalareae = 0;
    var lastPosYOrden = 0;
    var lastPosYforma = 0;
    var pagenumber = 0;
    this.pdf = new jspdf('p', 'pt', 'letter'); // A4 size page of PDF
    var position = 0;
    var source = (<any>$('#contentToConvert'))[0];
    var margins = {
      top: 80,
      bottom: 60,
      left: 40,
      width: 700
    };

    var fechainit = new Date(this.fechainicio);
    var fechafin = new Date(this.fechafinal);


    this.pdf.setFontStyle('bold');
    var fefechainit =  this.fechainicio.split("-");
    var fefechafin =  this.fechafinal.split("-");


    this.pdf.setFontSize(12);
    this.pdf.text('Desde: ' + (fefechainit[2] ) + "/" + (fefechainit[1]) + "/" + fefechainit[0] + ' Hasta: ' + ( fefechafin[2]) + "/" + (  fefechafin[1]) + "/" + fefechafin[0], margins.left, 110);

    var finalY = 108;
    var ordenes = this.aranceles.map(item => item.noOrdenpago).filter((value, index, self) => self.indexOf(value) === index);


    for (var i = 0; i < ordenes.length; i++) {
      //this.aranceles[i].precio =  this.aranceles[i].precio -  this.aranceles[i].exonerado;
      var bus = this.aranceles.filter(item => item.noOrdenpago === ordenes[i]);
      for (var j = 0; j < bus.length; j++) {
        bus[j].precio = this.transformiles(bus[j].precio);
        
      }

      var arancelesareasdistintas = this.aranceles.filter(item=> item.areaIngreso!=item.areaGenerada);

      var totalc = bus.filter(item => item.moneda === "Córdoba").map(item => item.precio).reduce((a, b) => a + b, 0);
      var totalec = bus.filter(item => item.moneda === "Córdoba").map(item => item.exonerado).reduce((a, b) => a + b, 0);
      var totald = bus.filter(item => item.moneda === "Dólar").map(item => item.precio).reduce((a, b) => a + b, 0);
      var totaled = bus.filter(item => item.moneda === "Dólar").map(item => item.exonerado).reduce((a, b) => a + b, 0);
      var totale = bus.filter(item => item.moneda === "Euro").map(item => item.precio).reduce((a, b) => a + b, 0);
      var totalee = bus.filter(item => item.moneda === "Euro").map(item => item.exonerado).reduce((a, b) => a + b, 0);
      var laste = bus.length;
      if (totalc > 0) {
        if (totalec > 0) {
          bus.push({
            idDetalleOrdenPago: 0,
            noOrdenpago: 0,
            idOrdenPago: 0,
            idArancelPrecio: 0,
            descuento: "",
            fechaCreacion: '',
            usuarioCreacion: "",
            precioVariable: 0,
            isPrecioVariable: false,
            concepto: "",
            color: "",
            count: 0,
            styles: "{halign: 'center'}",
            arancel: '',
            moneda: 'Exonerado  C$',
            precio: this.transformiles(totalec)
          });
        }
        bus.push({
          idDetalleOrdenPago: 0,
          noOrdenpago: 0,
          idOrdenPago: 0,
          idArancelPrecio: 0,
          descuento: "",
          fechaCreacion: '',
          usuarioCreacion: "",
          precioVariable: 0,
          isPrecioVariable: false,
          concepto: "",
          color: "",
          count: 0,
          styles: "{halign: 'center'}",
          arancel: '',
          moneda: 'Total  C$',
          precio: this.transformiles(totalc)
        });


      }
      if (totald > 0) {
        if (totaled > 0) {
          bus.push({
            idDetalleOrdenPago: 0,
            noOrdenpago: 0,
            idOrdenPago: 0,
            idArancelPrecio: 0,
            descuento: "",
            fechaCreacion: '',
            usuarioCreacion: "",
            precioVariable: 0,
            isPrecioVariable: false,
            concepto: "",
            color: "",
            count: 0,
            styles: "{halign: 'center'}",
            'arancel': '',
            'moneda': 'Exonerado  $',
            'precio': this.transformiles(totaled)
          });
        }
        bus.push({
          idDetalleOrdenPago: 0,
          noOrdenpago: 0,
          idOrdenPago: 0,
          idArancelPrecio: 0,
          descuento: "",
          fechaCreacion: '',
          usuarioCreacion: "",
          precioVariable: 0,
          isPrecioVariable: false,
          concepto: "",
          color: "",
          count: 0,
          styles: "{halign: 'center'}",
          'arancel': '',
          'moneda': 'Total  $',
          'precio': this.transformiles(totald)
        });


      }
      if (totale > 0) {
        if (totalee > 0) {
          bus.push({
            idDetalleOrdenPago: 0,
            noOrdenpago: 0,
            idOrdenPago: 0,
            idArancelPrecio: 0,
            descuento: "",
            fechaCreacion: '',
            usuarioCreacion: "",
            precioVariable: 0,
            isPrecioVariable: false,
            concepto: "",
            color: "",
            count: 0,
            styles: "{halign: 'center'}",
            'arancel': '',
            'moneda': 'Exonerado  €',
            'precio': this.transformiles(totaled)
          });
        }
        bus.push({
          idDetalleOrdenPago: 0,
          noOrdenpago: 0,
          idOrdenPago: 0,
          idArancelPrecio: 0,
          descuento: "",
          fechaCreacion: '',
          usuarioCreacion: "",
          precioVariable: 0,
          isPrecioVariable: false,
          concepto: "",
          color: "",
          count: 0,
          styles: "{halign: 'center'}",
          'arancel': '',
          'moneda': 'Total  €',
          'precio': this.transformiles(totald)
        });


      }

      lastPosYOrden = this.pdf.autoTableEndPosY();

      pagenumber = this.pdf.internal.getNumberOfPages();



      if (lastPosYOrden == 0) {
        lastPosYOrden = 125;
      }

      if (lastPosYforma > lastPosYOrden) {
        lastPosYOrden = lastPosYforma;
      }

      if (lastPosYOrden > 0) {
        lastPosYOrden = lastPosYOrden + 25;
      }
      this.pdf.setFontSize(10);
      this.pdf.setFontStyle('bold');
      var ff = new Date(bus[0].fechaCreacion);
      this.pdf.text("Orden No. " + ordenes[i], margins.left, (lastPosYOrden - 3))
      this.pdf.setFontSize(8);
      this.pdf.setFontStyle('bold');
      text = " Generada: " + ff.toLocaleDateString() + " " + ff.toLocaleTimeString();
      xOffset = (this.pdf.internal.pageSize.width / 2) + 35 - (this.pdf.getStringUnitWidth(text) * this.pdf.internal.getFontSize()) - margins.left;
      this.pdf.text(text, xOffset, (lastPosYOrden - 3));




      this.pdf.autoTable({
        columns: [
          { header: 'Arancel', dataKey: 'arancel' },
          { header: 'Moneda', dataKey: 'moneda' },
          { header: 'Precio', dataKey: 'precio' }
        ],
        body: bus,
        startY: lastPosYOrden,
        margin: {
          top: 125,
          right: this.pdf.internal.pageSize.width / 2
        },
        styles: {
          lineWidth: 0.3,
          lineColor: [0, 0, 0]

        },

        headStyles: {
          fillColor: [0, 55, 130],
        },
        columnStyles: {
          'arancel': {
            cellWidth: 140
          },
          'precio': {
            halign: 'right'
          }
        }
        ,
        didParseCell: data => {

          if (data.section === 'body' && data.column.dataKey === 'moneda' && data.row.index === laste) {

            data.cell.styles.fontStyle = "bold";

          }
          if (data.section === 'body' && data.column.dataKey === 'moneda' && data.row.index === laste + 1) {

            data.cell.styles.fontStyle = "bold";

          }
          if (data.section === 'body' && data.column.dataKey === 'precio' && data.row.index === laste) {

            data.cell.styles.fontStyle = "bold";

          }
          if (data.section === 'body' && data.column.dataKey === 'precio' && data.row.index === laste + 1) {

            data.cell.styles.fontStyle = "bold";


          }


        }
      });
      finalY = this.pdf.autoTable.previous.finalY;

      var busr = this.recibos.filter(item => item.noOrdenPago === ordenes[i]);

     
      var totalrc = busr.filter(item => item.moneda === "Córdoba").map(item => item.monto).reduce((a, b) => a + b, 0);
      var totalrd = busr.filter(item => item.moneda === "Dólar").map(item => item.monto).reduce((a, b) => a + b, 0);
      var totalre = busr.filter(item => item.moneda === "Euro").map(item => item.monto).reduce((a, b) => a + b, 0);
      var laster = busr.length;
      if (totalrc > 0) {
        busr.push({

          'noOrdenPago': 0,
          'formaPago': '',
          tasa: 0,
          recibo: '',
          'fechaCreacion': '',
          usuarioCreacion: '',
          detalleFormaPago: '',
          'moneda': 'Total  C$',
          'monto': this.transformiles(totalrc)
        });
      }
      if (totalrd > 0) {
        busr.push({
          'noOrdenPago': 0,

          tasa: 0,
          recibo: '',
          'formaPago': '',
          'fechaCreacion': '',
          detalleFormaPago: '',
          usuarioCreacion: '',
          'moneda': 'Total  $',
          'monto': this.transformiles(totalrd)
        });
      }
      if (totalre > 0) {
        busr.push({
          'noOrdenPago': 0,
          tasa: 0,
          recibo: '',
          'formaPago': '',
          'fechaCreacion': '',
          detalleFormaPago: '',
          usuarioCreacion: '',
          'moneda': 'Total  €',
          'monto': this.transformiles(totalre)
        });
      }

      totalareac = totalareac + totalrc;
      totalaread = totalaread + totalrd;
      totalareae = totalareae + totalre;
      if (pagenumber !== this.pdf.internal.getNumberOfPages()) {
        this.pdf.setPage(this.pdf.internal.getNumberOfPages() - 1);

      }
      this.pdf.setFontSize(8);
      this.pdf.setFontStyle('bold');
      var ff = new Date(busr[0].fechaCreacion);
      this.pdf.text("Recibo No. " + busr[0].recibo + " T/C : " + busr[0].tasa, ((this.pdf.internal.pageSize.width / 2) + 10), (lastPosYOrden - 3))
      this.pdf.setFontSize(8);
      this.pdf.setFontStyle('bold');
      text = " Generado: " + ff.toLocaleDateString() + " " + ff.toLocaleTimeString();
      xOffset = (this.pdf.internal.pageSize.width) - (this.pdf.getStringUnitWidth(text) * this.pdf.internal.getFontSize()) - margins.left;
      this.pdf.text(text, xOffset, (lastPosYOrden - 3));

      lastPosYforma = this.pdf.autoTableEndPosY();

  
      this.pdf.autoTable({
        columns: [

          { header: 'Detalle de Pago', dataKey: 'detalleFormaPago' },
          { header: 'Moneda', dataKey: 'moneda' },
          { header: 'Monto', dataKey: 'monto' }
        ],
        body: busr,
        startY: lastPosYOrden,

        margin: {
          top: 125,
          left: (this.pdf.internal.pageSize.width / 2) + 10
        },
        styles: {
          lineWidth: 0.3,
          lineColor: [0, 0, 0]

        },
        columnStyles: {
          'formaPago': {
            cellWidth: 140
          },
          'monto': {
            halign: 'right'
          }
        }
        ,
        didParseCell: data => {


          if (data.section === 'body' && data.column.dataKey === 'moneda' && data.row.index === laster) {

            data.cell.styles.fontStyle = "bold";

          }
          if (data.section === 'body' && data.column.dataKey === 'moneda' && data.row.index === laster + 1) {

            data.cell.styles.fontStyle = "bold";

          }
          if (data.section === 'body' && data.column.dataKey === 'monto' && data.row.index === laster) {

            data.cell.styles.fontStyle = "bold";

          }
          if (data.section === 'body' && data.column.dataKey === 'monto' && data.row.index === laster + 1) {

            data.cell.styles.fontStyle = "bold";

          }
        }
        ,
        headStyles: {
          fillColor: [224, 224, 224],
          textColor: [66, 66, 66]
        },
      });
      finalY = this.pdf.autoTable.previous.finalY;
    }

    

    if (lastPosYOrden == 0) {
      lastPosYOrden = 125;
    }
    if (pagenumber != this.pdf.internal.getNumberOfPages()) {
      if (lastPosYforma < lastPosYOrden) {
        lastPosYOrden = lastPosYforma;
      }
    } else {
      if (lastPosYforma > lastPosYOrden) {
        lastPosYOrden = lastPosYforma;
      }
    }


    if (lastPosYforma > 0) {
      lastPosYOrden = lastPosYOrden + 28;
    }
    this.pdf.setFontSize(14);
    this.pdf.setFontStyle('bold');
    var formas = this.recibos.map(item => item.formaPago).filter((value, index, self) => self.indexOf(value) === index);
    formas.sort();
    var totalesformas: formapagorep[];
    for (var i = 0; i < formas.length; i++) {
      var totalcc = this.recibos.filter(item => item.moneda === "Córdoba" && item.formaPago == formas[i]).map(item => item.monto).reduce((a, b) => a + b, 0);
      var totaldd = this.recibos.filter(item => item.moneda === "Dólar" && item.formaPago == formas[i]).map(item => item.monto).reduce((a, b) => a + b, 0);

      if (totalcc > 0) {
        if (totalesformas == undefined) {

          totalesformas = [{
            forma: formas[i],
            moneda: "Córdoba",
            total: this.transformiles(totalcc)
          }];

        } else {
          totalesformas.push({
            forma: formas[i],
            moneda: "Córdoba",
            total: this.transformiles(totalcc)
          })
        }
      }
      if (totaldd > 0) {
        if (totalesformas == undefined) {
          totalesformas = [{
            forma: formas[i],
            moneda: "Dólar",
            total: this.transformiles(totaldd)
          }];
        } else {
          totalesformas.push({
            forma: formas[i],
            moneda: "Dólar",
            total: this.transformiles(totaldd)
          })
        }
      }
    }


    if(arancelesareasdistintas.length> 0 ){
      if (lastPosYforma > 0) {
        lastPosYOrden = lastPosYOrden + 28;
      }


      var totaldiferentecordoba = arancelesareasdistintas.filter(item => item.moneda === "Córdoba").map(item => item.precio).reduce((a, b) => a + b, 0);
      var totaldiferentedolar = arancelesareasdistintas.filter(item => item.moneda === "Dólar").map(item => item.precio).reduce((a, b) => a + b, 0);
      var totaldiferenteeuro = arancelesareasdistintas.filter(item => item.moneda === "Euro").map(item => item.precio).reduce((a, b) => a + b, 0);
      if (totaldiferentecordoba > 0) {
        arancelesareasdistintas.push({
          idDetalleOrdenPago: 0,
          noOrdenpago: 0,
          idOrdenPago: 0,
          idArancelPrecio: 0,
          descuento: "",
          fechaCreacion: '',
          usuarioCreacion: "",
          precioVariable: 0,
          isPrecioVariable: false,
          concepto: "",
          arancel: "",
          color: "",
          count: 0,
          styles: "{halign: 'center'}",
          moneda: "Total C$",
          precio: this.transformiles(totaldiferentecordoba)
        })
      }
      if (totaldiferentedolar > 0) {
        arancelesareasdistintas.push({
          idDetalleOrdenPago: 0,
          noOrdenpago: 0,
          idOrdenPago: 0,
          idArancelPrecio: 0,
          descuento: "",
          fechaCreacion: '',
          usuarioCreacion: "",
          precioVariable: 0,
          isPrecioVariable: false,
          concepto: "",
          arancel: "",
          color: "",
          count: 0,
          styles: "{halign: 'center'}",
          moneda: "Total $",
          precio: this.transformiles(totaldiferentedolar)
        })
      }
      if (totaldiferenteeuro > 0) {
        arancelesareasdistintas.push({
          idDetalleOrdenPago: 0,
          noOrdenpago: 0,
          idOrdenPago: 0,
          idArancelPrecio: 0,
          descuento: "",
          fechaCreacion: '',
          usuarioCreacion: "",
          precioVariable: 0,
          isPrecioVariable: false,
          concepto: "",
          arancel: "",
          color: "",
          count: 0,
          styles: "{halign: 'center'}",
          moneda: "Total €",
          precio: this.transformiles(totaldiferenteeuro)
        })
      }


      this.pdf.text('Aranceles Pertenecientes a otra área', margins.left, lastPosYOrden - 4);
  
      this.pdf.autoTable({
        columns: [
          { header: 'Aranceles', dataKey: 'arancel' },
          { header: 'Concepto', dataKey: 'concepto' },
          { header: 'Orden Pago', dataKey: 'noOrdenpago' },
          { header: 'Área', dataKey: 'areaDiferente' },
          { header: 'Moneda', dataKey: 'moneda' },
          { header: 'Total', dataKey: 'precio' }
        ],
        body: arancelesareasdistintas,
        margin: { top: 125 },
        startY: lastPosYOrden,
        styles: {
          lineWidth: 0.3,
          lineColor: [0, 0, 0]
  
        },
        columnStyles: {
          'concepto': {
            cellWidth: 150
          }
        },
        headStyles: {
          fillColor: [0, 0, 0],
        },
        didParseCell: data => {
  
        }
      });
     

      finalY = this.pdf.autoTable.previous.finalY;
    }

    
   

    if (totalareac > 0) {
      totalesformas.push({
        forma: "",
        moneda: "Total C$",
        total: this.transformiles(totalareac)
      })
    }
    if (totalaread > 0) {
      totalesformas.push({
        forma: "",
        moneda: "Total $",
        total: this.transformiles(totalaread)
      })
    }

    if (totalareae > 0) {
      totalesformas.push({
        forma: "",
        moneda: "Total €",
        total: this.transformiles(totalareae)
      })
    }

    this.pdf.setFontSize(14);
    this.pdf.setFontStyle('bold');
    var fin = this.pdf.autoTable.previous.finalY + 45;

    //pagenumber = this.pdf.internal.getNumberOfPages();
    this.pdf.setPage(this.pdf.internal.getNumberOfPages());
    var lastPosyTotales = this.pdf.autoTableEndPosY();




    this.pdf.text('Total en cuenta del area', margins.left, this.pdf.autoTable.previous.finalY + 15);

    this.pdf.autoTable({
      columns: [
        { header: 'Ingresos del area', dataKey: 'forma' },
        { header: 'Moneda', dataKey: 'moneda' },
        { header: 'Total', dataKey: 'total' }
      ],
      body: totalesformas,
      margin: { top: 125 },
      startY: this.pdf.autoTable.previous.finalY + 25,
      styles: {
        lineWidth: 0.3,
        lineColor: [0, 0, 0]

      },
      columnStyles: {
        'forma': {
          cellWidth: 300
        }
      },
      headStyles: {
        fillColor: [0, 0, 0],
      },
      didParseCell: data => {

        if (data.section === 'body' && data.column.dataKey === 'moneda' && data.row.index === (totalesformas.length - 2)) {

          data.cell.styles.fontStyle = "bold";

        }
        if (data.section === 'body' && data.column.dataKey === 'moneda' && data.row.index === (totalesformas.length - 1)) {

          data.cell.styles.fontStyle = "bold";

        }
        if (data.section === 'body' && data.column.dataKey === 'total' && data.row.index === (totalesformas.length - 2)) {

          data.cell.styles.fontStyle = "bold";

        }
        if (data.section === 'body' && data.column.dataKey === 'total' && data.row.index === (totalesformas.length - 1)) {

          data.cell.styles.fontStyle = "bold";

        }
      }
    });



    var pageCount = this.pdf.internal.getNumberOfPages();
    for (var i = 0; i < pageCount; i++) {
      this.pdf.setPage(i);
      this.pdf.setFontSize(12);
      this.pdf.setFontStyle('bold');
      this.pdf.addImage(this.logouni, 'PNG', margins.left, 15, 75, 50)
      var text = "UNIVERSIDAD NACIONAL DE INGENIERÍA ",
        xOffset = (this.pdf.internal.pageSize.width / 2) - (this.pdf.getStringUnitWidth(text) * this.pdf.internal.getFontSize() / 2);
      this.pdf.text(text, xOffset, 30);
      text = 'DIVISION DE FINANZAS';
      xOffset = (this.pdf.internal.pageSize.width / 2) - (this.pdf.getStringUnitWidth(text) * this.pdf.internal.getFontSize() / 2);
      this.pdf.text(text, xOffset, 45);
      text = 'OFICINA DE CONTABILIDAD';
      xOffset = (this.pdf.internal.pageSize.width / 2) - (this.pdf.getStringUnitWidth(text) * this.pdf.internal.getFontSize() / 2);
      this.pdf.text(text, xOffset, 60);
      this.pdf.setDrawColor(0, 0, 0);
      this.pdf.setLineWidth(2.0);
      this.pdf.line(margins.left, 67, 570, 65);

      // pdf.text(textOffset, 80,'');
      text = 'INFORME DE INGRESOS POR ORDENES DE PAGO';
      xOffset = (this.pdf.internal.pageSize.width / 2) - (this.pdf.getStringUnitWidth(text) * this.pdf.internal.getFontSize() / 2);
      this.pdf.text(text, xOffset, 80);
      text = this.dse.Area;
      xOffset = (this.pdf.internal.pageSize.width / 2) - (this.pdf.getStringUnitWidth(text) * this.pdf.internal.getFontSize() / 2);
      this.pdf.text(text, xOffset, 95);


      this.pdf.setFontSize(8);
      this.pdf.setFontStyle('italic');
      if (i == 0) {
        this.pdf.text('Página ' + pageCount + ' de ' + pageCount, 520, 770);
      } else {
        this.pdf.text('Página ' + i + ' de ' + pageCount, 520, 770);
      }
      var fi = new Date();
      this.pdf.setFontStyle('bold');
      this.pdf.text('Sistema de Ordenes de Pago UNI ', margins.left, 770);
      this.pdf.text('Fecha de impresión:  ' + fi.toLocaleDateString() + ' ' + fi.toLocaleTimeString() + '      Generado por: ' + this.dse.User, margins.left, 780);
      this.pdf.setDrawColor(0, 0, 0);
      this.pdf.setLineWidth(2.0);
      this.pdf.line(margins.left, 760, 570, 760);

    }

    this.reporte = this.pdf.output('datauristring');
    //$('#pdfviewer').attr('src', this.reporte);
    this.pdfSrc = this.reporte;


  }
  // tslint:disable-next-line: comment-format
  //Boton imprimir Informe de ingresos de area
  public imprimir() {


    this.pdf.save("Informe de ingresos de ordenes de pago " + this.fechainicio + " al " + this.fechafinal);


  }

  public imprimirexo() {


    this.pdfexo.save("Informe de Exoneraciones del area " + this.fechainicioexo + " al " + this.fechafinalexo);


  }

  public imprimirordenes() {


    this.pdfordenes.save("Informe General de Ordenes de Pago del area " + this.fechainicioordenes + " al " + this.fechafinalordenes);


  }


  public removecollapseordenes() {

    (<any>$('#multiCollapseexoneracion')).removeClass('show');
    (<any>$('#multiCollapseexoneracion')).addClass('collpasing');

    (<any>$('#multiCollapseingreso')).removeClass('show');
    (<any>$('#multiCollapseingreso')).addClass('collpasing');


  }

  public removecollapseingreso() {

    (<any>$('#multiCollapseexoneracion')).removeClass('show');
    (<any>$('#multiCollapseexoneracion')).addClass('collpasing');

    (<any>$('#multiCollapseordenes')).removeClass('show');
    (<any>$('#multiCollapseordenes')).addClass('collpasing');


  }

  public removecollapseexoneracion() {

    (<any>$('#multiCollapseingreso')).removeClass('show');
    (<any>$('#multiCollapseingreso')).addClass('collpasing');

    (<any>$('#multiCollapseordenes')).removeClass('show');
    (<any>$('#multiCollapseordenes')).addClass('collpasing');


  }


  public clickgenexoneracion() {
    if (this.fechafinalexo != undefined && this.fechainicioexo != undefined && this.fechainicioexo != null && this.fechafinalexo != null) {
      this.reportegexo();


    } else {

      this.errorreporte1 = "Debe seleccionar una fecha inicial y final para generar el reporte.";

    }
  }

  public reportegexo() {
    this.errorreporte2 = "";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.dse.Token);

    this.options = new RequestOptions({ headers: headers });
    this.spinnerService.show();
    this.http.get('../Reportes/ExoneracionesPagadas?FechaFin=' + this.fechafinalexo + '&FechaInicio=' + this.fechainicioexo + "&arancel=" + this.arancelexo + "&moneda=" + this.monedaexo, this.options).subscribe(data => {
      if (data.status == 200) {
        this.exoneraciones = data.json().data;
        if (this.exoneraciones.length == 0) {
          this.errorreporte2 = "Este Periodo no contiene Exoneraciones generadas.";
        } else {
          this.captureScreenexo();
        }

      }
      this.spinnerService.hide();
    });
  }

  public captureScreenexo() {


    this.pdfexo = new jspdf('l', 'pt', 'letter'); // A4 size page of pdfexo  
    var position = 0;
    var source = (<any>$('#contentToConvert'))[0];
    var margins = {
      top: 80,
      bottom: 60,
      left: 40,
      width: 700
    };
    var fechainit = new Date(this.fechainicioexo);
    var fechafin = new Date(this.fechafinalexo);
    var fefechainit =  this.fechainicioexo.split("-");
    var fefechafin =  this.fechafinalexo.split("-");


    this.pdfexo.setFontSize(12);
    this.pdfexo.text('Desde: ' + (fefechainit[2] ) + "/" + (fefechainit[1]) + "/" + fefechainit[0] + ' Hasta: ' + ( fefechafin[2]) + "/" + (  fefechafin[1]) + "/" + fefechafin[0], margins.left, 110);


    this.pdfexo.setFontSize(12);
    this.pdfexo.setFontStyle('bold');
    this.pdfexo.text('Exoneraciones generadas', margins.left, 135);

    var bus = this.exoneraciones.filter(item => item.recibo == undefined);
    var totald = bus.filter(item => item.simbolo === "$").map(item => item.exonerado).reduce((a, b) => a + b, 0);
    var totalc = bus.filter(item => item.simbolo === "C$").map(item => item.exonerado).reduce((a, b) => a + b, 0);



    var busa = this.exoneraciones.filter(item => item.recibo != undefined);
    var totalda = busa.filter(item => item.simbolo === "$").map(item => item.exonerado).reduce((a, b) => a + b, 0);
    var totalca = busa.filter(item => item.simbolo === "C$").map(item => item.exonerado).reduce((a, b) => a + b, 0);


    if (totalda > 0) {
      busa.push({
        arancel: "",
        tipoDeposito: "",
        identificador: "",
        usuarioCreacion: "",
        autorizadopor: "",
        noOrdenPago: "",
        precio: "",
        total: "Total $",
        exonerado: "$ " + this.transformiles(totalda),

      });
    }
    if (totalca > 0) {
      busa.push({
        arancel: "",
        tipoDeposito: "",
        identificador: "",
        usuarioCreacion: "",
        autorizadopor: "",
        noOrdenPago: "",
        precio: "",
        total: "Total C$",
        exonerado: "C$ " + this.transformiles(totalca),


      });
    }


    if (totald > 0) {
      bus.push({
        arancel: "",
        tipoDeposito: "",
        identificador: "",
        usuarioCreacion: "",
        autorizadopor: "",
        noOrdenPago: "",
        precio: "Total $",
        total: "",
        exonerado: "$ " + this.transformiles(totald),

      });
    }
    if (totalc > 0) {
      bus.push({
        arancel: "",
        tipoDeposito: "",
        identificador: "",
        usuarioCreacion: "",
        autorizadopor: "",
        noOrdenPago: "",
        precio: "Total C$",
        total: "",
        exonerado: "C$ " + this.transformiles(totalc),


      });
    }

    for (var i = 0; i < this.exoneraciones.length; i++) {
      if (this.exoneraciones[i].precio == 0) {
        this.exoneraciones[i].precio = "Precio Variable";
      } else {
        this.exoneraciones[i].precio = this.exoneraciones[i].simbolo + " " + this.exoneraciones[i].precio;
      }

      if (this.exoneraciones[i].recibo == undefined) {
        this.exoneraciones[i].recibo = "Sin Usar";
      }
      if (this.exoneraciones[i].autorizadopor == undefined) {
        this.exoneraciones[i].autorizadopor = "Sin Autorizar";
      }
      this.exoneraciones[i].exonerado = this.exoneraciones[i].simbolo + " " + this.exoneraciones[i].exonerado;
      this.exoneraciones[i].exonerado = this.transformiles(this.exoneraciones[i].exonerado);
      this.exoneraciones[i].total = this.exoneraciones[i].simbolo + " " + this.transformiles(this.exoneraciones[i].total);


    }


    var laste = bus.length;
    this.pdfexo.autoTable({
      columns: [

        { header: 'Arancel', dataKey: 'arancel' },
        { header: 'Usuario', dataKey: 'tipoDeposito' },
        { header: 'Identificación', dataKey: 'identificador' },
        { header: 'Creado', dataKey: 'usuarioCreacion' },
        { header: 'Autorizado', dataKey: 'autorizadopor' },
        { header: 'Fecha', dataKey: 'fechaCreacion' },
        { header: 'Precio', dataKey: 'precio' },
        { header: 'Exonerado', dataKey: 'exonerado' }


      ],
      body: bus,
      margin: { top: 140 },
      styles: {
        lineWidth: 0.3,
        lineColor: [0, 0, 0],
        fontSize: 8

      },

      headStyles: {
        fillColor: [0, 55, 130],
      },
      columnStyles: {
        'arancel': {
          cellWidth: 200  
        }
      }
      ,
      didParseCell: data => {

        /* if (data.section === 'body' && data.column.dataKey === 'precio' && data.row.index === laste) {
         
          data.cell.styles.fontStyle= "bold" ;
        
         }
         if (data.section === 'body' && data.column.dataKey === 'precio' && data.row.index === laste+1) {
 
           data.cell.styles.fontStyle = "bold";
 
         }
 
         if (data.section === 'body' && data.column.dataKey === 'exonerado' && data.row.index === laste) {
 
           data.cell.styles.fontStyle = "bold";
 
         }
         if (data.section === 'body' && data.column.dataKey === 'exonerado' && data.row.index === laste + 1) {
 
           data.cell.styles.fontStyle = "bold";
 
 
         } */

      }
    });


    var top = this.pdfexo.autoTable.previous.finalY;
    var lastPosY = this.pdfexo.autoTableEndPosY();
    if (busa.length > 0) {
      this.pdfexo.setFontSize(12);
      this.pdfexo.setFontStyle('bold');
      this.pdfexo.text('Exoneraciones Aplicadas ', margins.left, top + 30);

      var lastea = busa.length;

      this.pdfexo.autoTable({
        columns: [
          { header: 'Recibo', dataKey: 'recibo' },
          { header: 'Arancel', dataKey: 'arancel' },
          { header: 'Usuario', dataKey: 'tipoDeposito' },
          { header: 'Identificacíon', dataKey: 'identificador' },
          { header: 'Creado', dataKey: 'usuarioCreacion' },
          { header: 'Autorizado', dataKey: 'autorizadopor' },
          { header: 'Fecha', dataKey: 'fechaCreacion' },
          { header: 'Precio', dataKey: 'precio' },
          { header: 'Total Pagado', dataKey: 'total' },
          { header: 'Exonerado', dataKey: 'exonerado' }


        ],
        body: busa,
        startY: lastPosY + 35,
        styles: {
          lineWidth: 0.3,
          lineColor: [0, 0, 0],
          fontSize: 8

        },

        headStyles: {
          fillColor: [0, 55, 130],
        },
        columnStyles: {
          'arancel': {
            cellWidth: 175
          }
        }
        ,
        didParseCell: data => {

        }
      });
    }
    else {
      this.pdfexo.setFontSize(12);
      this.pdfexo.setFontStyle('bold');
      this.pdfexo.text('No hay Exoneraciones Aplicadas en este período ', margins.left, top + 30);


    }

    var pageCount = this.pdfexo.internal.getNumberOfPages();
    for (var i = 0; i < pageCount; i++) {
      this.pdfexo.setPage(i);
      this.pdfexo.setFontSize(12);
      this.pdfexo.setFontStyle('bold');
      this.pdfexo.addImage(this.logouni, 'PNG', margins.left, 15, 75, 50)
      
      var text = "UNIVERSIDAD NACIONAL DE INGENIERÍA ",
        xOffset = (this.pdfexo.internal.pageSize.width / 2) - (this.pdfexo.getStringUnitWidth(text) * this.pdfexo.internal.getFontSize() / 2);
      this.pdfexo.text(text, xOffset, 30);
      text = 'DIVISION DE FINANZAS';
      xOffset = (this.pdfexo.internal.pageSize.width / 2) - (this.pdfexo.getStringUnitWidth(text) * this.pdfexo.internal.getFontSize() / 2);
      this.pdfexo.text(text, xOffset, 45);
      text = 'OFICINA DE CONTABILIDAD';
      xOffset = (this.pdfexo.internal.pageSize.width / 2) - (this.pdfexo.getStringUnitWidth(text) * this.pdfexo.internal.getFontSize() / 2);
      this.pdfexo.text(text, xOffset, 60);
      this.pdfexo.setDrawColor(0, 0, 0);
      this.pdfexo.setLineWidth(2.0);
      this.pdfexo.line(margins.left, 67, 750, 65);

      // pdfexo.text(textOffset, 80,'');
      text = 'INFORME DE EXONERACIONES';
      xOffset = (this.pdfexo.internal.pageSize.width / 2) - (this.pdfexo.getStringUnitWidth(text) * this.pdfexo.internal.getFontSize() / 2);
      this.pdfexo.text(text, xOffset, 80);

      text = this.dse.Area;
      xOffset = (this.pdfexo.internal.pageSize.width / 2) - (this.pdfexo.getStringUnitWidth(text) * this.pdfexo.internal.getFontSize() / 2);
      this.pdfexo.text(text, xOffset, 95);

      this.pdfexo.setFontSize(8);
      this.pdfexo.setFontStyle('italic');
      if (i == 0) {
        this.pdfexo.text('Página ' + pageCount + ' de ' + pageCount, 695, 580);
      } else {
        this.pdfexo.text('Página ' + i + ' de ' + pageCount, 695, 580);
      }
      var fi = new Date();
      this.pdfexo.setFontStyle('bold');
      this.pdfexo.text('Sistema de Ordenes de Pago UNI ', margins.left, 580);
      this.pdfexo.text('Fecha de impresión:  ' + fi.toLocaleDateString() + ' ' + fi.toLocaleTimeString() + '      Generado por: ' + this.dse.User, margins.left, 590);
      this.pdfexo.setDrawColor(0, 0, 0);
      this.pdfexo.setLineWidth(2.0);
      this.pdfexo.line(margins.left, 570, 750, 570);

    }

    this.reporte = this.pdfexo.output('datauristring');
    //$('#pdfviewer').attr('src', this.reporte);
    this.pdfSrcexo = this.reporte;


  }


  public clickgenordenes() {
    if (this.fechafinalordenes != undefined && this.fechainicioordenes != undefined && this.fechainicioordenes != null && this.fechafinalordenes != null) {
      this.reportegordenes();


    } else {

      this.errorreporte3 = "Debe seleccionar una fecha inicial y final para generar el reporte.";

    }
  }

  public reportegordenes() {
    this.errorreporte3 = "";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.dse.Token);

    this.options = new RequestOptions({ headers: headers });
    this.spinnerService.show();
    this.http.get('../Reportes/OrdenesGeneradasAsync?FechaFin=' + this.fechafinalordenes + '&FechaInicio=' + this.fechainicioordenes, this.options).subscribe(data => {
      if (data.status == 200) {
        this.ordenes = data.json().data;
        if (this.ordenes.length == 0) {
          this.errorreporte3 = "Este Periodo no contiene Ordenes Generadas.";
        } else {
          this.captureScreenordenes();
        }

      }
      this.spinnerService.hide();
    });
  }


  public captureScreenordenes() {


    this.pdfordenes = new jspdf('l', 'pt', 'letter'); // A4 size page of pdfexo  
    var position = 0;
    var source = (<any>$('#contentToConvert'))[0];
    var margins = {
      top: 80,
      bottom: 60,
      left: 40,
      width: 700
    };
    var fechainit = new Date(this.fechainicioordenes);
    var fechafin = new Date(this.fechafinalordenes);
   var fefechainit =  this.fechainicioordenes.split("-");
    var fefechafin =  this.fechafinalordenes.split("-");


    this.pdfordenes.setFontSize(12);
    this.pdfordenes.text('Desde: ' + (fefechainit[2] ) + "/" + (fefechainit[1]) + "/" + fefechainit[0] + ' Hasta: ' + ( fefechafin[2]) + "/" + (  fefechafin[1]) + "/" + fefechafin[0], margins.left, 110);

    this.pdfordenes.setFontSize(12);
    this.pdfordenes.setFontStyle('bold');
    this.pdfordenes.text('Ordenes generadas', margins.left, 135);

    //var generadas = this.ordenes.filter(item => item.recibo == undefined);

    const tableordenes = this.ordenes;

    let totaldolares = 0;
    let totalcordobas = 0;
    var totalcordobaspagado = this.ordenes.filter(item => item.estado === "Cancelada").map(item => item.totalcor).reduce((a, b) => a + b, 0);
    var totaldolarespagado = this.ordenes.filter(item => item.estado === "Cancelada").map(item => item.totalusd).reduce((a, b) => a + b, 0);
    var totalcordobasanulado = this.ordenes.filter(item => item.estado === "Anulada").map(item => item.totalcor).reduce((a, b) => a + b, 0);
    var totaldolaresanulado = this.ordenes.filter(item => item.estado === "Anulada").map(item => item.totalusd).reduce((a, b) => a + b, 0);
    var totalcordobasgenerado = this.ordenes.filter(item => item.estado === "Generada").map(item => item.totalcor).reduce((a, b) => a + b, 0);
    var totaldolaresgenerado = this.ordenes.filter(item => item.estado === "Generada").map(item => item.totalusd).reduce((a, b) => a + b, 0);
    this.barChartData = [
      { data: [totaldolaresanulado, totalcordobasanulado], label: 'Anulados' },
      { data: [totaldolarespagado, totalcordobaspagado], label: 'Cancelados' },
      { data: [totaldolaresgenerado, totalcordobasgenerado], label: 'Generados' }
      
    ];

  

    for (var i = 0; i < tableordenes.length; i++) {
      totaldolares = totaldolares + tableordenes[i].totalusd;
      totalcordobas = totalcordobas + tableordenes[i].totalcor;
      tableordenes[i].totalusd = "U$ " + this.transformiles(tableordenes[i].totalusd);
      tableordenes[i].totalcor = "C$ " + this.transformiles(tableordenes[i].totalcor);

    }



    // var totalIngreso = new [any,any,any];

    this.pdfordenes.autoTable({
      columns: [
        { header: 'N° Item', dataKey: 'noItem' },
        { header: 'N° OP', dataKey: 'noOrdenpago' },
        { header: 'Destino', dataKey: 'destino' },
        { header: 'Estado', dataKey: 'estado' },
        { header: 'Fecha Creada', dataKey: 'fecha' },
        { header: 'Identificación', dataKey: 'identificacion' },
        { header: 'Usuario', dataKey: 'usuario' },
        { header: 'Recibo', dataKey: 'recibo' },
        { header: 'Fecha Recibo', dataKey: 'fecharecibo' },
        { header: 'T/C', dataKey: 'tc' },
        { header: 'Total U$', dataKey: 'totalusd' },
        { header: 'Total C$', dataKey: 'totalcor' }

      ],
      body: tableordenes,
      margin: { top: 140 },
      styles: {
        lineWidth: 0.3,
        lineColor: [0, 0, 0],
        fontSize: 8

      },

      headStyles: {
        fillColor: [0, 55, 130],
      },
      columnStyles: {
        'noItem': {
          cellWidth: 25
        },
        'noOrdenpago': {
          cellWidth: 65
        },
        'destino': {
          cellWidth: 48
        },
        'estado': {
          cellWidth: 58
        },
        'fecha': {
          cellWidth: 70
        },
        'identificacion': {
          cellWidth: 75
        },
        'usuario': {
          cellWidth: 75
        },
        'recibo': {
          cellWidth: 50
        },
        'fecharecibo': {
          cellWidth: 60
        },
        'tc': {
          cellWidth: 40,
          halign: 'right'
        },
        'totalusd': {
          cellWidth: 65,
          halign: 'right'
        },
        'totalcor': {
          cellWidth: 65,
          halign: 'right'
        },
      }

      ,
      didParseCell: data => {

        /* if (data.section === 'body' && data.column.dataKey === 'precio' && data.row.index === laste) {
         
          data.cell.styles.fontStyle= "bold" ;
        
         }
         if (data.section === 'body' && data.column.dataKey === 'precio' && data.row.index === laste+1) {
 
           data.cell.styles.fontStyle = "bold";
 
         }
 
         if (data.section === 'body' && data.column.dataKey === 'exonerado' && data.row.index === laste) {
 
           data.cell.styles.fontStyle = "bold";
 
         }
         if (data.section === 'body' && data.column.dataKey === 'exonerado' && data.row.index === laste + 1) {
 
           data.cell.styles.fontStyle = "bold";
 
 
         } */

      }
    });

    //this.pdfordenes.text('Total en Ordenes de pago', margins.left, this.pdfordenes.autoTableEndPosY);
    let lastPosY = this.pdfordenes.autoTableEndPosY();
    
    this.pdfordenes.autoTable({
      head: [
        [{ content: 'Moneda', styles: { halign: 'center', cellWidth: 120 } }, { content: 'Total', styles: { halign: 'center', cellWidth: 120 } }]
      ],
      body: [
        ['Cordobas (Totales)', { content: 'C$ ' + this.transformiles(totalcordobas), styles: { halign: 'right' } }],
        ['Dolar (Totales)', { content: 'U$ ' + this.transformiles(totaldolares), styles: { halign: 'right' } }],
        ['Cordobas (Pendientes)', { content: 'C$ ' + this.transformiles(totalcordobasgenerado), styles: { halign: 'right' } }],
        ['Dolar (Pendientes)', { content: 'U$ ' + this.transformiles(totaldolaresgenerado), styles: { halign: 'right' } }],
        ['Cordobas (Cancelados)', { content: 'U$ ' + this.transformiles(totalcordobaspagado), styles: { halign: 'right' } }],
        ['Dolar (Cancelados)', { content: 'C$ ' + this.transformiles(totaldolarespagado), styles: { halign: 'right' } }],
        ['Cordobas (Anulados)', { content: 'U$ ' + this.transformiles(totalcordobasanulado), styles: { halign: 'right' } }],
        ['Dolar (Anulados)', { content: 'C$ ' + this.transformiles(totaldolaresanulado), styles: { halign: 'right' } }]

      ],
      margin: { top: 125 },
      //startY: lastPosYOrden,
      styles: {
        lineWidth: 0.3,
        lineColor: [0, 0, 0]

      },

      headStyles: {
        fillColor: [0, 0, 0],
      }

    });
  
  
    var pageCount = this.pdfordenes.internal.getNumberOfPages();
    for (var i = 0; i < pageCount; i++) {
      this.pdfordenes.setPage(i);
      this.pdfordenes.setFontSize(12);
      this.pdfordenes.setFontStyle('bold');
      this.pdfordenes.addImage(this.logouni, 'PNG', margins.left, 15, 75, 50);
      var text = "UNIVERSIDAD NACIONAL DE INGENIERÍA ",
        xOffset = (this.pdfordenes.internal.pageSize.width / 2) - (this.pdfordenes.getStringUnitWidth(text) * this.pdfordenes.internal.getFontSize() / 2);
      this.pdfordenes.text(text, xOffset, 30);
      text = 'DIVISION DE FINANZAS';
      xOffset = (this.pdfordenes.internal.pageSize.width / 2) - (this.pdfordenes.getStringUnitWidth(text) * this.pdfordenes.internal.getFontSize() / 2);
      this.pdfordenes.text(text, xOffset, 45);
      text = 'OFICINA DE CONTABILIDAD';
      xOffset = (this.pdfordenes.internal.pageSize.width / 2) - (this.pdfordenes.getStringUnitWidth(text) * this.pdfordenes.internal.getFontSize() / 2);
      this.pdfordenes.text(text, xOffset, 60);
      this.pdfordenes.setDrawColor(0, 0, 0);
      this.pdfordenes.setLineWidth(2.0);
      this.pdfordenes.line(margins.left, 67, 750, 65);

      // pdfexo.text(textOffset, 80,'');
      text = 'INFORME GENERAL DE ORDENES DE PAGO';
      xOffset = (this.pdfordenes.internal.pageSize.width / 2) - (this.pdfordenes.getStringUnitWidth(text) * this.pdfordenes.internal.getFontSize() / 2);
      this.pdfordenes.text(text, xOffset, 80);

      text = this.dse.Area;
      xOffset = (this.pdfordenes.internal.pageSize.width / 2) - (this.pdfordenes.getStringUnitWidth(text) * this.pdfordenes.internal.getFontSize() / 2);
      this.pdfordenes.text(text, xOffset, 95);

      this.pdfordenes.setFontSize(8);
      this.pdfordenes.setFontStyle('italic');
      if (i == 0) {
        this.pdfordenes.text('Página ' + pageCount + ' de ' + pageCount, 695, 580);
      } else {
        this.pdfordenes.text('Página ' + i + ' de ' + pageCount, 695, 580);
      }
      var fi = new Date();
      this.pdfordenes.setFontStyle('bold');
      this.pdfordenes.text('Sistema de Ordenes de Pago UNI ', margins.left, 580);
      this.pdfordenes.text('Fecha de impresión:  ' + fi.toLocaleDateString() + ' ' + fi.toLocaleTimeString() + '      Generado por: ' + this.dse.User, margins.left, 590);
      this.pdfordenes.setDrawColor(0, 0, 0);
      this.pdfordenes.setLineWidth(2.0);
      this.pdfordenes.line(margins.left, 570, 750, 570);

    }
   /*  var imagenreporte =  <HTMLCanvasElement>document.getElementById("canvasreport");
    
   
    this.pdfordenes.addImage(imagenreporte, 'PNG', 300, lastPosY , 400, 300);
 */
    this.reporteordenes = this.pdfordenes.output('datauristring');
    // $('#pdfviewer').attr('src', this.reporte);
    this.pdfSrcordenes = this.reporteordenes;

  }


  public transformiles(value: any) {
    /*  const formmater = new Intl.NumberFormat('es-ni', {
       style: 'currency',
       currency: 'USD',
       minimumFractionDigits: 2
     }); */
    const format = Number(value).toFixed(2);
    return format.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // return formmater.format(value);
  }
  downloadCanvas(event) {
    // get the `<a>` element from click event
    var anchor = event.target;
    // get the canvas, I'm getting it by tag name, you can do by id
    // and set the href of the anchor to the canvas dataUrl
    anchor.href = document.getElementById('canvasreport')[0].toDataURL();
    // set the anchors 'download' attibute (name of the file to be downloaded)
    anchor.download = "test.png";
}


}



