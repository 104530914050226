export class exoneracion {
  idExoneracion: number;
  exonerado: string;
  idArancelPrecio: string;
  arancel: string;
  identificador: string;
  idTipoDeposito: string;
  tipoDeposito: string;
  usuarioCreacion: string;
  fechaCreacion: string;
  autorizadopor: string;
  noOrdenPago: string;
  idMoneda: number;
  idrecinto?: string;
  simbolo: string;
  tipoprecio:boolean;
  precio : string;
  count: number;



}
