import { Injectable } from '@angular/core';
import { Http, Headers,RequestOptions } from '@angular/http';
import { LoginForm } from '../components/Login/LoginForm'




@Injectable()
export class HttpService {

  constructor(protected _http: Http) {}

   Login(Login: LoginForm) {
       const headers = new Headers();
        headers.append('Content-Type', 'application/json');
       const options = new RequestOptions({headers: headers}); 

        return this._http.post('../token', {"user" : Login.email, "pass": Login.pass});

    }


}
