import { Component, Inject, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Http, Headers, RequestOptions, Response  } from '@angular/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Observable, interval} from 'rxjs';
import { Router, NavigationStart, Event } from '@angular/router';

import { DataService } from '../../_service/globalsvariable';

import { arancel } from '../../_class/arancel';
import { arancelarea } from '../../_class/arancelarea';
import { cuentacontable } from '../../_class/cuentacontable';
import { tipoarancel } from '../../_class/tipoarancel';
import { area } from '../../_class/area';
import { deposito } from '../../_class/deposito';
import { recinto } from '../../_class/recinto';
import { moneda } from '../../_class/moneda';

declare var $: any;



//import 'rxjs/add/operator/toPromise';

import * as XLSX from 'xlsx';

//import { ServerDataSource } from 'ng2-smart-table';
//import { OnInit } from '@angular/core/src/metadata/lifecycle_hooks';





@Component({
    selector: 'aranceles',
  templateUrl: './arancel.component.html',
  styleUrls: ['./arancel.component.css']

})


export class ArancelComponent implements OnInit {

    display = 'none';
    @ViewChild('autocompleteorigen', {static: false}) autocompleteorigen;
    @ViewChild('autocompletedestino', {static: false}) autocompletedestino;
    @ViewChild('autocompletearea', {static: false}) autocompletearea;
    @ViewChild('autocompleteareaingreso', {static: false}) autocompleteareaingreso;
    @ViewChild('modalcopi', {static: false}) modalcopi: ElementRef;

    public data: arancel[];
  public dataarea: arancelarea[];
  public areasaranceles: arancelarea[];
  public areasarancelessintodo: arancelarea[];
  public listaarancelesarea: arancel[];
    public datadepositos: deposito[];
    public recintos: recinto[];
    public monedas: moneda[];
  public areas: arancelarea[];
  public areastabla: arancelarea[];
  public depositostabla: deposito[];
    public cuentas: cuentacontable[];
    public depositos: deposito[];
  public tipos: tipoarancel[];
  areaingresoselect:any;
  public listaarancelesareacopia: arancelarea[];


      Tk: any;

  searchModel = new arancel();
  searchArancelesAreaModel = new arancel();
  searchModel2 = new arancelarea();
    searchModel3 = new deposito();
  ArancelModel = new arancel();
  AreaModel = new arancelarea();
    DepositoModel = new deposito();


    nuevo: boolean;
    errorareas: boolean;
    errorgestionar: boolean;
    errorguardar: boolean;
    erroreliminar: boolean;
    errordepositos: boolean;
    selected: string;
    headers: any;
    Token: any;
    pagination = 10;
    pagination2 = 0;
    pagination3 = 0;

    options: any;
    timerbool = true;
    p1 = 1;
    p2 = 1;
    p3 = 1;
    timer: any;
    timer2: any;
  hashData = '';
  hashDatapermisos = '';

  SaveModel = new arancel();
  SaveModel2 = new arancelarea();
    SaveModel3 = new deposito();
  editModel = new arancelarea();
  editModel2 = new deposito();

  dataservice: DataService;
  keyword = 'nombre';
  keyword2 = 'descripcion';
  keyword3 = 'area';
  pantalla = 'Aranceles';
  permisoagregar = false;
  permisoeditar = false;
  permisoeliminar = false;
  permisoexcel = false;
  permisoclonararanceles = false;

  errorcopiar = false;
  successcopiar = false;

  areaorigin = '';
  areadestino = '';
  arancelorigin = '';
  aranceldestino = '';
  precioorigin = '';
  preciodestino = '';
  busquedaorigin = '';
  busquedadestino = '';

  errortoAdd = '';

  //@ViewChild('itemTemplate2') icuenta;



  constructor(private http: Http, private spinnerService: Ng4LoadingSpinnerService, private router: Router, dataservice: DataService) {



        this.searchModel.nombre = '';
        this.searchModel.descripcion = '';
        this.searchModel.cuenta = '';
        this.searchModel.fecha = '';
        this.searchModel.moneda = '';
        this.searchModel.precio = '';
        this.searchModel.tipo = '';
        this.searchModel.areabusqueda = '';
        this.searchModel.usuariocreacion = '';
        this.searchModel2.area = '';
        this.searchModel2.precio = '';
        this.searchModel2.moneda = '';
        this.searchModel2.recinto = '';
        this.searchModel3.tipoDeposito = '';
        this.SaveModel.idarancel = 0;

    this.listaarancelesareacopia = [];





        this.errorareas = false;
        this.errorgestionar = false;
        this.errorguardar = false;
        this.erroreliminar = false;
        this.errordepositos = false;
        this.nuevo = true;

        this.dataservice = dataservice;




    }

  ngOnInit() {

    let hd = new Headers();
    hd.append('Content-Type', 'application/json');
let ops = new RequestOptions({ headers: hd });

  let data = this.http.post('../Usuarios/tokenizador',  ops).toPromise();

    data.then((data => {
      if (data.status == 200) {
        this.Token = data.json().token;
        this.headers = new Headers();
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Authorization', 'Bearer ' + data.json().token);
        this.options = new RequestOptions({ headers: this.headers });
        this.listaareas();
        this.listaareasconaranceles();
        this.listarecintos();
        this.listatipos();
        this.listacuentas();
        this.listamonedas();
       // this.listadepositos();
       this.Modelchange();
       this.Modelchangepermisos();
      }
      }));






    }

    ngOnDestroy() {
        this.timerbool = false;
  }


  Selecttipoarancel() {
    this.depositostabla = [];
    this.listadepositos();

  }

  autorizados() {
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.guardar, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoagregar = true;
      }

      }, error => {

        if (error.status == 401) {
          this.permisoagregar = false;
        }


      }

      );
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.editar, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoeditar = true;
      }

      }, error => {

        if (error.status == 401) {
          this.permisoeditar = false;
        }
      });
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.eliminar, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoeliminar = true;
      }

      }, error => {

        if (error.status == 401) {
          this.permisoeliminar = false;
        } }

    );
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.excel, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoexcel = true;
      }

      }, error => {

        if (error.status == 401) {
          this.permisoexcel = false;
        }
      });
    this.http.post('../Usuarios/PermisoAccion', JSON.stringify({
      permiso: this.dataservice.clonarAranceles, pantalla: this.pantalla

    }), this.options).subscribe(data => {

      if (data.status == 200) {
        this.permisoclonararanceles = true;
      }

    }, error => {

      if (error.status == 401) {
        this.permisoclonararanceles = false;
      }
    });
  }
    openmodal(nueo: boolean): void {

        this.nuevo = nueo;
        if (this.nuevo) {
            this.clear();

        } else {
            this.areastabla = this.dataarea;
            this.depositostabla = this.datadepositos;

        }



    }

    stopUpdate(): void {
      this.timer.dispose();
      this.timer2.dispose();
    }


    deletemodal(): void {

        (<any> $('#DeleteModal')).modal('show');



  }

    detallemodal(): void {

      (<any>$('#DetalleModal')).modal('show');



  }

  clonar(): void {

    (<any>$('#ClonarModal')).modal('show');
    this.listaarancelesareacopia = [];
    this.listaarancelesarea = [];
    this.autocompletedestino.clear();
    this.autocompleteorigen.clear();
    this.modalcopi.nativeElement.click();

    this.AreaModel.objectarea = '';
    this.busquedadestino = '';
    this.errorcopiar = false;
    this.successcopiar = false;


  }

  export(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('selector'));
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Aranceles');
    /* save to file */
    XLSX.writeFile(wb, 'Aranceles Excel ' + new Date().toLocaleDateString() + '.xlsx');

  }


  export2(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('selector2'));
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Unidades Ejecutoras');
    /* save to file */
    XLSX.writeFile(wb, 'Unidades Ejecutoras Arancel ' + this.SaveModel.nombre + new Date().toLocaleDateString() + '.xlsx');

  }

  export3(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('selector3'));
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Aranceles');
    /* save to file */
    XLSX.writeFile(wb, 'Depositantes asociados Arancel ' + this.SaveModel.nombre  + new Date().toLocaleDateString() + '.xlsx');

  }


  Gestionar(): void {
    this.errorgestionar = false;
        if (this.nuevo) {
            this.guardar(true);
        } else {
            this.guardar(false);

        }
    }



    public clear(): void {

        this.areastabla = [];
      this.depositostabla = [];
      this.ArancelModel = new arancel();
     // this.SaveModel = new arancel();
      this.errorareas = false;
      this.errorguardar = false;


    }



  public selecttable(event: any, item: any) {

    this.SaveModel.idarancel = item.idArancel;
    this.SaveModel.nombre = item.nombre;
    this.SaveModel.descripcion = item.descripcion;
    this.SaveModel.idprecio = item.idprecio;
    this.SaveModel.precio = item.precio;
    this.SaveModel.idmoneda = item.idmoneda;
    this.SaveModel.moneda = item.moneda;
    this.SaveModel.idcuenta = item.idcuenta;
    this.SaveModel.cuenta = item.cuenta;
    this.SaveModel.fecha = item.fechaCreacion;
    this.SaveModel.usuariocreacion = item.usuarioCreacion;
    this.SaveModel.idrecinto = item.idRecinto;
    this.SaveModel.idtipo = item.idtipo;
    this.SaveModel.isPrecioVariable = item.isPrecioVariable;
    this.ArancelModel.nombre = this.SaveModel.nombre + '';
    this.ArancelModel.descripcion = this.SaveModel.descripcion + '';
    this.ArancelModel.idcuenta = this.SaveModel.idcuenta;
    this.ArancelModel.cuenta = String(this.SaveModel.cuenta);
    this.ArancelModel.precio = this.SaveModel.precio;
    this.ArancelModel.isPrecioVariable = this.SaveModel.isPrecioVariable;
    this.ArancelModel.moneda = String(this.SaveModel.idmoneda);
    this.ArancelModel.idtipo = this.SaveModel.idtipo;

        this.search2();
        this.search3();
        this.Selecttipoarancel();



    }
  public selecttable2(event: any, item: any) {
    this.SaveModel2.idArancelArea = item.idArancelArea;
    this.SaveModel2.idArea = item.idArea;
        this.SaveModel2.area = item.descripcion;


    }

  public selecttable3(event: any, item: any) {

      this.editModel.idArea = item.idArea;
      this.editModel.idAreaDestino = item.idAreaDestino;




    }
    public selecttable4(event: any, item: any) {
        this.SaveModel3.idTipoDeposito = item.idTipoDeposito;
        this.SaveModel3.tipoDeposito = item.tipoDeposito;


    }

    public selecttable5(event: any, item: any) {
        this.editModel2.idTipoDeposito = item.idTipoDeposito;
      this.editModel2.tipoDeposito = item.tipoDeposito;


  }






     search(): void {
         this.spinnerService.show();
        // this.data =this.http.get('../Arancels/searchAranceles?_limit=' + this.pagination + '&_page=' + this.p1 + '&nombre=' + this.searchModel.nombre + '&descripcion=' + this.searchModel.descripcion + '&cuenta=' + this.searchModel.cuenta + '&fecha=' + this.searchModel.fecha + '&precio=' + this.searchModel.precio + '&moneda=' + this.searchModel.moneda + '&usuario=' + this.searchModel.usuariocreacion + '&tipo=' + this.searchModel.tipo + '&area=' + this.searchModel.areabusqueda , this.options);

       this.http.get('../Arancels/searchAranceles?_limit=' + this.pagination + '&_page=' + this.p1 + '&nombre=' + this.searchModel.nombre + '&descripcion=' + this.searchModel.descripcion + '&cuenta=' + this.searchModel.cuenta + '&fecha=' + this.searchModel.fecha + '&precio=' + this.searchModel.precio + '&moneda=' + this.searchModel.moneda + '&usuario=' + this.searchModel.usuariocreacion + '&tipo=' + this.searchModel.tipo + '&area=' + this.searchModel.areabusqueda , this.options).subscribe(data => {
             if (data.status == 200) {
                     this.data = data.json().data;
                     this.SaveModel.count = data.json().count;


             }
             this.spinnerService.hide();

         });
  }


  searcharancelesarea(): void {
     this.spinnerService.show();

    this.http.get('../Arancels/searchArancelesAreaconprecio?_limit=1000&_page=1&area=' + this.searchArancelesAreaModel.areabusqueda, this.options).subscribe(data => {

      if (data.status == 200) {



        this.listaarancelesarea = data.json().data;
      //  this.SaveModel.count = data.json().count;





      }
      this.spinnerService.hide();


    });



  }




    Modelchange(): void {
         this.spinnerService.show();
      this.http.get('../Arancels/searchAranceles?_limit=' + this.pagination + '&_page=' + this.p1 + '&nombre=' + this.searchModel.nombre + '&descripcion=' + this.searchModel.descripcion + '&cuenta=' + this.searchModel.cuenta + '&fecha=' + this.searchModel.fecha + '&precio=' + this.searchModel.precio + '&moneda=' + this.searchModel.moneda + '&usuario=' + this.searchModel.usuariocreacion + '&tipo=' + this.searchModel.tipo + '&area=' + this.searchModel.areabusqueda, this.options).subscribe(data => {

            if (data.status == 200) {

                if (this.hashData != data.json().hash) {
                    this.hashData = data.json().hash;
                    this.search();

                }
                this.spinnerService.hide();
            }


        });

  }

  Modelchangepermisos(): void {
    // this.spinnerService.show();
    this.http.get('../Usuarios/ModelChangePermisos?pantalla=' + this.pantalla, this.options).subscribe(data => {

      if (data.status == 200) {

        if (this.hashDatapermisos != data.json().hash) {
          this.hashDatapermisos = data.json().hash;
          this.autorizados();

        }
        //this.spinnerService.hide();
      }


    }, error => {

      //  this.spinnerService.hide();

    }, () => {
      //this.spinnerService.hide();

    });





  }

    listarecintos(): void {
        this.spinnerService.show();
        this.http.get('../Arancels/listaRecintos', this.options).subscribe(data => {

            if (data.status == 200) {
                this.recintos = data.json();
                this.spinnerService.hide();
            }

        }, error => {
            this.spinnerService.hide();
        }, () => { });
        this.spinnerService.hide();

    }
    listaareas(): void {
        this.spinnerService.show();
        this.http.get('../Arancels/listaareas', this.options).subscribe(data => {

            if (data.status == 200) {
              this.areas = data.json();
                this.spinnerService.hide();
            }

        }, error => {
            this.spinnerService.hide();
        }, () => { });
        this.spinnerService.hide();

  }

  listaareasconaranceles(): void {
    this.spinnerService.show();
    this.http.get('../Arancels/listaareasconaranceles?', this.options).subscribe(data => {

      if (data.status == 200) {
        this.areasaranceles = data.json();
        this.areasarancelessintodo = data.json();
        this.areasaranceles.unshift({ area: 'Todas las areas', idArea : 0 });
        this.spinnerService.hide();
      }

    }, error => {
      this.spinnerService.hide();
    }, () => { });
    this.spinnerService.hide();

  }

    listadepositos(): void {
    
        this.http.get('../Arancels/listadepositos?tipoarancel=' + this.ArancelModel.idtipo, this.options).subscribe(data => {

            if (data.status == 200) {
                this.depositos = data.json();
              
            }

        }, error => {
         
        }, () => { });



    }

  listatipos(): void {
    this.spinnerService.show();
    this.http.get('../Arancels/listatipos', this.options).subscribe(data => {

      if (data.status == 200) {
        this.tipos = data.json();
        this.spinnerService.hide();
      }

    }, error => {
      this.spinnerService.hide();
    }, () => {

    });



  }
    listamonedas(): void {
        this.spinnerService.show();
        this.http.get('../Arancels/listamonedas', this.options).subscribe(data => {

            if (data.status == 200) {
                this.monedas = data.json();
                this.spinnerService.hide();
            }

        }, error => {
            this.spinnerService.hide();
        }, () => { });
        this.spinnerService.hide();

    }


    listacuentas(): void {
        this.spinnerService.show();
        this.http.get('../Arancels/listacuentas', this.options).subscribe(data => {

            if (data.status == 200) {
                this.cuentas = data.json();
            }

        }, error => {

        }, () => { });

        this.spinnerService.hide();

    }
    getPage(page: number) {
        this.p1 = page;
        this.search();

    }


    search2(): void {
      this.dataarea = [];
        this.spinnerService.show();
        const diagnostics = this.http.get('../Arancels/searchArancelesarea?_limit=' +
       this.pagination2 + '&_page=' + this.p2 + '&idArancel=' +
       this.SaveModel.idarancel + '&area=' + this.searchModel2.area , this.options).toPromise()
       
       .then(data => {

            if (data.status == 200) {
                this.dataarea = data.json().data;

                this.SaveModel2.count = data.json().count;
              
               this.spinnerService.hide();
            }

        }).catch(

          error => {
            this.spinnerService.hide();

           
          }
        );

        if(diagnostics){
         // this.spinnerService.hide();
        }


    }

    search3(): void {
      this.http.get('../Arancels/searchArancelesdepositos?_limit=' + this.pagination3 + '&_page=' + this.p3 + '&idArancel=' + this.SaveModel.idarancel + '&deposito=' + this.searchModel3.tipoDeposito , this.options).subscribe(data => {

            if (data.status == 200) {
             
                this.datadepositos = data.json().data;
                this.SaveModel3.count = data.json().count;
              

            }

        }, error => {


        });


    }


   getPage2(page: number) {
        this.p2 = page;
        this.search2();

    }

    getPage3(page: number) {
        this.p3 = page;
        this.search3();

  }

  cambionet(item): void {


  }

  agregartab(): void {
    console.log(this.AreaModel.precio);
    if (this.ArancelModel.isPrecioVariable == true) {
      this.AreaModel.precio = '0';
    }

    if ((this.ArancelModel.isPrecioVariable == false && Number(this.AreaModel.precio) > 0 && this.AreaModel.idArea >= 0 && this.AreaModel.idAreaDestino >= 0 && this.AreaModel.idmoneda > 0) || (this.ArancelModel.isPrecioVariable == true && Number(this.AreaModel.precio) == 0 && this.AreaModel.idArea >= 0 && this.AreaModel.idmoneda > 0)) {
            this.errorareas = false;

      let areatemp = this.AreaModel.area;
      let r = (<any>$('#arancelmoneda option:selected')).text();


      let array = {
        idArancelArea: this.AreaModel.idArancelArea, idArea: this.AreaModel.idArea,
        idAreaDestino: this.AreaModel.idAreaDestino, areadestino: this.AreaModel.areadestino,
        idArancel: this.SaveModel.idarancel, idmoneda: this.AreaModel.idmoneda, precio: this.AreaModel.precio,
         area: areatemp, moneda: r , count: 0
      };


      let exist = this.areastabla.findIndex(x => x.area == areatemp && x.areadestino == this.AreaModel.areadestino );

           if (exist > -1) {
                this.areastabla.splice(exist, 1);
          }

            this.areastabla.push(array);
        } else {
            this.errorareas = true;
        }
    }

  eliminartab(): void {
    let areatemp = this.editModel.idArea;
        let exist = this.areastabla.findIndex(x => x.idArea == areatemp && x.idAreaDestino == this.editModel.idAreaDestino );

        if (exist > -1) {
            this.areastabla.splice(exist, 1);
        }

    }

    agregartab2(): void {
        if (this.DepositoModel.idTipoDeposito > 0) {
            this.errordepositos = false;
            let e = ((document.getElementById('tipodepositoid')) as HTMLSelectElement);
            let depositotemp = e.options[e.selectedIndex].text;
  let array = { idTipoDeposito: this.DepositoModel.idTipoDeposito, tipoDeposito: depositotemp, count : 0 };

            let exist = this.depositostabla.findIndex(x => x.idTipoDeposito == this.DepositoModel.idTipoDeposito);

            if (exist > -1) {
                this.depositostabla.splice(exist, 1);
            }

            this.depositostabla.push(array);
        } else {
            this.errordepositos = true;
        }
    }

    eliminartab2(): void {
        let depositotemp = this.editModel2.idTipoDeposito;

      let exist = this.depositostabla.findIndex(x => x.idTipoDeposito == depositotemp);

        if (exist > -1) {
            this.depositostabla.splice(exist, 1);
        }

    }


    guardar(nuevo: boolean): void {

      //if () {

            this.ejecutar(nuevo);

        //} else {
        //    this.errorgestionar = true;
        //}

  }

  selectarea(item): void {
    this.areaingresoselect = this.autocompletearea.query;
    this.AreaModel.idArea = item.codigo;
    this.AreaModel.area = item.descripcion;
 
    this.AreaModel.idAreaDestino = item.codigo;
    this.AreaModel.areadestino = item.descripcion;






  }


  selectareaingreso(item): void {
    this.AreaModel.idAreaDestino = item.codigo;
    this.AreaModel.areadestino = item.descripcion;
    console.log(this.areaingresoselect);





  }

  selectoptionmoneda(item): void {
    //this.AreaModel.idArea = item.codigo;
    //this.AreaModel.area = item.descripcion;







  }

  validateOnlyNumbers(evt) {
    let theEvent = evt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode( key );
    let regex = /[0-9]|\./;
    if ( !regex.test(key) ) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) { theEvent.preventDefault(); }
    }
  }

  selectarancelareacopia(item): void {



    this.areaorigin = this.searchArancelesAreaModel.areabusqueda;
    this.arancelorigin = item.idArancel +'';
    this.precioorigin = item.idprecio + '';


  }

  selectarancelareadestino(item) {
    this.areadestino = this.searchArancelesAreaModel.areabusqueda;
    this.aranceldestino = item.idArancel + '';
    this.preciodestino = item.idprecio + '';

  }


  selectareaarancel(item): void {


    this.searchModel.areabusqueda = item.idArea;
    this.search();


  }

  selectrecintoarancel(): void {

    this.listaareasconaranceles();


  }

  selectareaorigin(item): void {

    this.areaorigin = item.idArea;
    this.searchArancelesAreaModel.areabusqueda = item.idArea;

    this.searcharancelesarea();


    }

  selectareadestino(item): void {

    this.areadestino = item.codigo;
        if (this.listaarancelesareacopia) {
      for (let i = 0; i < this.listaarancelesareacopia.length; i++) {
        {
          this.listaarancelesareacopia[i].area = this.areadestino;
        }
      }
    }
  }


  isPrecioVariablechecked() {
    if (this.ArancelModel.isPrecioVariable) {
      this.ArancelModel.precio = '0';
    } else {
      this.ArancelModel.isPrecioVariable = false;
    }
  }

  selectcuenta(item): void {
    this.ArancelModel.idcuenta = item.idCuentaContable;
    //console.log(this.ArancelModel.idcuenta);

  }

  copiarunarancel() {


    let datos = this.listaarancelesarea.find(x => x.idprecio == Number(this.precioorigin));

    if (this.areaorigin.length > 0 && this.arancelorigin.length > 0 && this.precioorigin.length > 0) {
       //this.errorareas = false;

      let array = {
        idprecio: Number(this.precioorigin), area: this.areaorigin, idArancel: datos.idArancel, isPrecioVariable: datos.isPrecioVariable
        , nombre : datos.nombre, moneda: datos.moneda, precio: datos.precio
      };

      if (this.areadestino != undefined && this.areadestino != '') {
        array.area = this.areadestino;
      }


      let exist = this.listaarancelesareacopia.findIndex(x => x.idArancel == Number(this.arancelorigin));

      if (exist > -1) {
        this.listaarancelesareacopia.splice(exist, 1);
      }

      this.listaarancelesareacopia.push(array);
    } else {
      //this.errorareas = true;
    }


  }

  copiartodosarancel() {



    if (this.listaarancelesarea) {
      for (let i = 0; i < this.listaarancelesarea.length; i++) {
        this.areaorigin = this.AreaModel.objectarea.idArea;
        this.arancelorigin = this.listaarancelesarea[i].idArancel + '';
        this.precioorigin = this.listaarancelesarea[i].idprecio + '';
        this.copiarunarancel();
      }
    }
  }

  quitartodosarancel() {
    this.listaarancelesareacopia = [];
  }



  quitarunarancel(): void {
    if (this.preciodestino.length > 0) {
      let preciotemp = Number(this.preciodestino);
      let exist = this.listaarancelesareacopia.findIndex(x => x.idprecio == preciotemp);

      if (exist > -1) {
        this.listaarancelesareacopia.splice(exist, 1);
      }
    }
  }


  ejecutar(nuevo: boolean): void {

    if (this.ArancelModel.nombre.length > 0 && this.ArancelModel.idtipo > 0 && this.ArancelModel.descripcion.length > 0 && Number(this.ArancelModel.idcuenta) > 0 && this.areastabla.length > 0 && this.depositostabla.length > 0) {
            this.errorgestionar = false;
            this.errorguardar = false;
            this.spinnerService.show();


            if (nuevo) {
              this.http.post('../Arancels/Create', JSON.stringify({


                  arancel: this.ArancelModel.nombre, descripcion: this.ArancelModel.descripcion, idtipoarancel: this.ArancelModel.idtipo, cuenta: this.ArancelModel.idcuenta
                  , isPrecioVariable: this.ArancelModel.isPrecioVariable
                  , Areas: this.areastabla, Depositos: this.depositostabla


                }), this.options).subscribe(data => {

                    if (data.status == 200) {

                        if (data.json().message == 'error') {
                            this.errorguardar = true;

                        } else {
                            (<any>$('#ArancelModal')).modal('hide');
                          this.search();
                          this.listaareasconaranceles();
                        }



                    }

                }, error => {



                }, () => { this.spinnerService.hide(); });
            } else {
                this.http.post('../Arancels/Edit', JSON.stringify({
                    idarancel: this.SaveModel.idarancel,

                  arancel: this.ArancelModel.nombre, descripcion: this.ArancelModel.descripcion, cuenta: this.ArancelModel.idcuenta, idtipoarancel: this.ArancelModel.idtipo,

                  isPrecioVariable: this.ArancelModel.isPrecioVariable, Areas: this.areastabla, Depositos: this.depositostabla

                }), this.options).subscribe(data => {

                    if (data.status == 200) {

                        if (data.json().message == 'error') {
                            this.errorguardar = true;

                        } else {
                            (<any>$('#ArancelModal')).modal('hide');
                          this.search();
                          this.listaareasconaranceles();
                        }



                    }

                }, error => {



                }, () => { this.spinnerService.hide(); });
            }





        } else {
            this.errorgestionar = true;
            this.listaarancelesareacopia = [];
        }
    }





    eliminar(): void {
        if (this.SaveModel.idarancel > 0) {
            this.erroreliminar = false;
            this.spinnerService.show();

            this.http.post('../Arancels/Delete', JSON.stringify({
                idArancel: this.SaveModel.idarancel
            }), this.options).subscribe(data => {

                if (data.status == 200) {

                    if (data.json().message == 'error') {
                      this.erroreliminar = true;

                        this.spinnerService.hide();
                    } else {
                      (<any>$('#DeleteModal')).modal('hide');
                        this.spinnerService.hide();
                      this.search();
                      this.listaareasconaranceles();


                        }
                }

            }, error => {



            }, () => {  });

        }

  }

  ejecutarcopia() {
    this.successcopiar = false;
    this.errorcopiar = false;
    let str = '';
    if (this.listaarancelesareacopia && (this.areadestino != '' && this.areadestino != undefined)) {


      this.http.post('../Arancels/CopiarAranceles',

       this.listaarancelesareacopia
     , this.options).subscribe(data => {

        if (data.status == 200) {

          if (data.json().message == 'error') {
            this.errorcopiar = true;
            if ( data.json().error != 'undefined') {

              for (let i = 0; i < data.json().error.length; i++) {

                str = str + '' + data.json().error[i];
                if (i < data.json().error.length - 1) {
                  str = str + '<br></br>';
                }
              }
              this.errortoAdd = str;

            }


          } else {
            this.successcopiar = true;

          }



        }

      }, error => {

          this.errorcopiar = true;


      }, () => { this.spinnerService.hide(); });
    }
  }

    private extractData(res: Response) {
        const body = res.json();
        return body || {};
    }

    private handleErrorObservable(error: Response | any) {

        return Observable.throw(error.message || error);
    }


}


















